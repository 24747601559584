import React, { useState,useEffect } from "react";
import services from "../../services/services";


const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState("");
    const [loggedUserId, setlLggedUserId] = useState(sessionStorage.getItem('userIdval'));

    const [showLoader, setShowLoader] = useState(0);

    const validate = () => {
        let tempErrors = {};
        let isValid = true;

        if (!oldPassword) {
            tempErrors["oldPassword"] = "Old password is required";
            isValid = false;
        }

        if (!newPassword) {
            tempErrors["newPassword"] = "New password is required";
            isValid = false;
        } else if (newPassword.length < 8) {
            tempErrors["newPassword"] = "Password must be at least 8 characters long";
            isValid = false;
        } else if (!/[A-Z]/.test(newPassword) || !/[a-z]/.test(newPassword) || !/[0-9]/.test(newPassword) || !/[!@#$%^&*]/.test(newPassword)) {
            tempErrors["newPassword"] = "Password must include uppercase, lowercase, number, and symbol";
            isValid = false;
        }

        if (!confirmPassword) {
            tempErrors["confirmPassword"] = "Please confirm your new password";
            isValid = false;
        } else if (newPassword !== confirmPassword) {
            tempErrors["confirmPassword"] = "Passwords do not match";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            setShowLoader(1);
            try {

                const trimmedOldPassword = oldPassword.trim();
                const trimmedNewPassword = newPassword.trim();

                const requestJsonTask = {
                    oldPassword: trimmedOldPassword,
                    newPassword: trimmedNewPassword
                };
                const response = await services.updatePasswordUser(requestJsonTask, loggedUserId);
               
                // //setSuccessMessage("Password changed successfully!");
                // //setErrors({}); 
               
                // if (response.ok) {
                //     setSuccessMessage("Password changed successfully!");
                //     setErrors({});
                // } else {
                //     const data = await response.json();
                //     setErrors({ apiError: data.message || "Failed to change password" });
                // }
                //alert(response);
                const data = response;//await response.json();
                

                if (data.message == "Password updated successfully") {
                    setShowLoader(0);
                    setSuccessMessage(data.message);
                    setErrors({});

                    setTimeout(() => {
                        window.location.href = "/profile";
                    }, 5000);

                } else {
                    setShowLoader(0);
                    setErrors({ apiError: data.message });
                }
              
            } catch (error) {
                //alert(error)
                setShowLoader(0);
                setErrors({ apiError: "Something went wrong. Please try again later." });
            }
        }
    };


    useEffect(() => {
        

    }, []);

  return(
  <>
   <main>
      <section>
      <div class="container">
        <h2 class="mainHeadline">
            Change Password
        </h2> <small>
            Must be min 8 characters long <br/>
A combination of Upper & Lower case Letters, numbers, and symbols.
        </small>


        <div className="mainbox my-4">
                        <div className="container px-0">
                            <div className="row">
                                <div className="col-lg-6">
                                    <form onSubmit={handleSubmit}>

                                    {showLoader === 1 ?
                    <div class="loadwrapper">
                        <div class="clock"><div class="circle"></div></div>
                    </div>
                    : '' }

                                        <div className="mb-3">
                                            <label className="form-label">Old Password*</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                value={oldPassword}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                            />
                                            {errors.oldPassword && <div className="text-danger">{errors.oldPassword}</div>}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">New Password*</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                            {errors.newPassword && <div className="text-danger">{errors.newPassword}</div>}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Confirm Password*</label>
                                            <input 
                                                type="password" 
                                                className="form-control" 
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
                                        </div>

                                        {errors.apiError && <div className="text-danger">{errors.apiError}</div>}
                                        {successMessage && <div className="text-success">{successMessage}</div>}

                                        <div className="my-3">
                                            <button type="submit" className="btn btn-primary">Change Password</button>
                                            <a href="/profile" className="btn">Cancel</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

    </div>
         </section>
         
      </main>
  </>
)};

ChangePassword.propTypes = {};

ChangePassword.defaultProps = {};

export default ChangePassword;
