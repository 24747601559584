import http from "../http-common"
import pubhttp from "../http-public"
import axios from 'axios';
var accessToken = sessionStorage.getItem("accessToken");
class Service {

  fetchServerAPIEndPoint() {
    return process.env.React_App_AWS_API
  }

  updatePasswordUser(requestJson,loggedUserId) {
    const requestOptions = {
      method: 'PUT', // Changed from 'POST' to 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/auth/update-password/${loggedUserId}`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        //alert(JSON.stringify(responseData));
        return responseData;
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  } 

  forgotPasswordReq(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/auth/forgot-password`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        return responseData;
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }

  deleteProfilePic(requestJson,loggedUserId) {
    const requestOptions = {
      method: 'PUT', // Changed from 'POST' to 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/users/${loggedUserId}/emptyProfilePicById`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  } 

  updateProfilePic(requestJson,loggedUserId) {
    const requestOptions = {
      method: 'PUT', // Changed from 'POST' to 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/users/${loggedUserId}/profile-pic`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }


  

  fetchUserWiseStats(requestJson) {
    //const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/user-wise-stats`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }

  fetchUserWiseStatsDashboard(requestJson) {
    //const accessToken = localStorage.getItem("accessToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/user-wise-stats`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }

  fetchTimeEntryStats(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/time-entry-stats`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }


  fetchTimeSheetStatusReport(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/time-sheet-status-report`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }

  fetchProjectWiseStats(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/project-wise-stats`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }

  fetchProjectWiseStatsDashboard(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/project-wise-stats`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }


  fetchProjectWiseStatsUser(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/project-wise-statsByUser`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }

  fetchProjectByUser(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/project/getProjectsByUser`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }

  fetchClientWiseStatsUser(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/client-wise-stats-user`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }

  fetchProjectWiseStatsUser(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/project-wise-stats-user`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }

  fetchProjectWiseStatsUserDashboard(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/project-wise-stats-user-dashboard`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }

  fetchTaskWiseStatsUser(requestJson) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(requestJson),
    };

    return fetch(`${process.env.React_App_AWS_API}/time-entries/task-wise-stats-user`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        if (responseData.type === 'success') {
          return responseData.data;
        } else {
          console.error('Failed to fetch data:', responseData.message);
          return [];
        }
      })
      .catch(error => {
        console.error('Error:', error);
        return [];
      });
  }


  createUser(payload) {
    const requestJson = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload),
    };

    return fetch(`${process.env.React_App_AWS_API}/users/create`, requestJson)
      .then(response => response.json())
      .then(responseData => responseData)
      .catch(error => {
        console.error('Error creating user:', error);
        return { name: "network error", description: "" };
      });
  }
  getAllTimeSheetEntries(clientid,projectid,taskid,weektimeentryid, isbillable,timesheetstatus,userid, requestdate, weekstartdate, weekenddate){
    return http.get(`/time-entries/getAllTimeEntries?clientId=`+clientid+`&projectId=`+projectid+`&taskId=`+taskid+`&weekTimeEntryId=`+weektimeentryid+`&isBillable=`+isbillable+`&timeSheetStatus=`+timesheetstatus+`&userId=`+userid+`&day=`+requestdate+`&weekStartDate=`+weekstartdate+`&weekEndDate=`+weekenddate);    
  }

  getAllTimeSheetEntrieswithTotalTime(clientid,projectid,projectstatus,weektimeentryid,userid, requestdate, weekstartdate, weekenddate){
    return http.get(`/time-entries/getTotalTime?userId=`+userid+`&weekTimeEntryId=`+weektimeentryid+`&clientId=`+clientid+`&projectId=`+projectid+`&projectStatus=`+projectstatus+`&day=`+requestdate+`&weekStartDate=`+weekstartdate+`&weekEndDate=`+weekenddate);
  }

  getAllTimeSheetEntriesForWeek(clientid,projectid,projectstatus,weektimeentryid,userid, requestdate, weekstartdate, weekenddate){
    return http.get(`/time-entries/getTotalTimeGroupedByProject?userId=`+userid+`&weekTimeEntryId=`+weektimeentryid+`&clientId=`+clientid+`&projectId=`+projectid+`&projectStatus=`+projectstatus+`&day=`+requestdate+`&weekStartDate=`+weekstartdate+`&weekEndDate=`+weekenddate);
  }

  getAllTasks(projectid,taskstatus,taskbillable,tasksearch){
    return http.get(`/tasks/getAllTasks?status=`+taskstatus+`&billable=`+taskbillable+`&projectId=`+projectid+`&search=`+tasksearch);
  }

  createTimesheetEntry(requestJson)
  {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };

      return fetch(process.env.React_App_AWS_API+"/time-entries/create",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  createNewTask(requestJson)
  {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };

      return fetch(process.env.React_App_AWS_API+"/tasks/create",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  assignTasktoUser(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };


    return fetch(process.env.React_App_AWS_API+"task-users/create",requestOptions)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }

  getFullWeekTimesInfo(requestJson)
  {

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };
      return fetch(process.env.React_App_AWS_API+"time-entries/calculate-hours",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getTaskDetails(taskid){
    return http.get(`/tasks/getTaskById/`+taskid);
  }

  deleteByTaskEntryId(taskentryid){
    return http.delete(`/time-entries/deleteById/`+taskentryid);
  }

  deleteTaskEntryByProjectId(requestJson)
  {
    const requestOptions = {
      method: 'DELETE',  // Set method to DELETE
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Pass the requestJson as the body
    };  
    
    return fetch(process.env.React_App_AWS_API+"time-entries/deleteTimeEntries",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  submitTimeSheetForApproval(requestJson){
   
    const requestOptions = {
      method: 'PUT',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };
    return fetch(process.env.React_App_AWS_API+"time-entries/update-status",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getWeekTimeEntryId(status,userid,dateval){
    return http.get(`/week-time-entry/getAll?status=`+status+`&userId=`+userid+`&date=`+dateval);
  }

  getTimeEntryInfobyId(timeentryid){
    return http.get(`/time-entries/getTimeEnteryById/`+timeentryid);
  }

  updateTimeSheetEntryById(requestJson, timesheetid){

    const requestOptions = {
      method: 'PUT',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"/time-entries/updateById/"+timesheetid,requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getTimeEntriesByStatus(requestJson)
  {
     
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"time-entries/week-based-hours",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  deleteEntriesByTaskSequence(requestJson)
  {
      
    const requestOptions = {
      method: 'DELETE',  // Set method to DELETE
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Pass the requestJson as the body
    };

    return fetch(process.env.React_App_AWS_API+"time-entries/deleteByFilter",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  updateEntriesonWeek(requestJson)
  {
      
    const requestOptions = {
      method: 'PUT',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"time-entries/updateWeekTimeEntries",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  createOrUpdateEntriesonWeek(requestJson)
  {
     
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"time-entries/create-or-update",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  deleteTimeEntriesById(requestJson)
  {
      
    const requestOptions = {
      method: 'DELETE',  // Set method to DELETE
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Pass the requestJson as the body
    };

    return fetch(process.env.React_App_AWS_API+"time-entries/deleteById",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getCompanyList(companystat){
    return http.get(`/company/getAllCompanys?status=`+companystat);
  }

  getCompanyListInital(companystat, token) {
    return http.get(`/company/getAllCompanys?status=` + companystat, {
      headers: {
        Authorization: `Bearer ${token}` // Send the token in the Authorization header
      }
    });
  }

  getClientsList(companyid, clientstatus, pagenum, itemsperpage, managerid)
  {
    return http.get(`/client/getAllClients?companyId=`+companyid+`&status=`+clientstatus+`&projectId=&pageNumber=`+pagenum+`&pageSize=`+itemsperpage+`&managerId=`+managerid);
  }

  getClientsListAdmin(companyid, clientstatus, pagenum, itemsperpage, managerid,sortOrder)
  {
    return http.get(`/client/getAllClients?companyId=`+companyid+`&status=`+clientstatus+`&projectId=&pageNumber=`+pagenum+`&pageSize=`+itemsperpage+`&managerId=`+managerid+`&sortOrder=`+sortOrder);
  }

  getClientInfoById(clientId)
  {
      return http.get(`/client/getClientById/`+clientId);
  }

  updateClientById(requestJson, clientid){
    
    const requestOptions = {
      method: 'PUT',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"client/updateById/"+clientid,requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  createClient(requestJson)
  {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };  
    
    return fetch(process.env.React_App_AWS_API+"client/create",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  deleteClientInfoById(clientId)
  {
      return http.get(`/client/deleteById/`+clientId);
  }

  getProjectsList(clientId, projectstatus, sortingorder, companyid)
  {
    return http.get(`/project/getAllProjects?clientId=`+clientId+`&status=`+projectstatus+`&sort=`+sortingorder+`&pageNumber=&pageSize=&companyId=`+companyid);
  }

  getAllProjects(clientid, status, companyid, pageSize){
    return http.get(`/project/getAllProjects?clientId=`+clientid+`&status=`+status+`&sort=asc&pageNumber=1&pageSize=`+pageSize+`&companyId=`+companyid);
  }

  getProjectsDetailsList(clientId, projectstatus, sortingorder, pagenum, pagesize,managerid, clientStatusval, companyidval)
  {
      return http.get(`/project/details?clientId=`+clientId+`&projectStatus=`+projectstatus+`&sortOrder=`+sortingorder+`&pageNumber=`+pagenum+`&pageSize=`+pagesize+`&managerId=`+managerid+`&clientStatus=`+clientStatusval+'&companyId='+companyidval);
  }

  
  getProjectsDetailsListAdmin(clientId, projectstatus, sortingorder, pagenum, pagesize,managerid, clientStatusval, companyidval,sortOrder,sortBy)
  {
      return http.get(`/project/details?clientId=`+clientId+`&projectStatus=`+projectstatus+`&sortOrder2=`+sortingorder+`&pageNumber=`+pagenum+`&pageSize=`+pagesize+`&managerId=`+managerid+`&clientStatus=`+clientStatusval+'&companyId='+companyidval+'&sortOrder='+sortOrder+'&sortBy='+sortBy);
  }

  updateProjectById(requestJson, projectid){

    const requestOptions = {
      method: 'PUT',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"project/updateById/"+projectid,requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  // getUsersByRole(userRole, userStaus, companyid, pagenum, pagesize){
  //   return http.get(`/users/getAllUsers?roleId=`+userRole+`&status=`+userStaus+`&companyId=`+companyid+`&pageNumber=`+pagenum+`&pageSize=`+pagesize);
  // }

  getUsersByRole(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };  

    return fetch(process.env.React_App_AWS_API+"users/getAllUsers",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  createProject(requestJson)
  {
      
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };  

    return fetch(process.env.React_App_AWS_API+"project/create",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  createProjectManager(requestJson)
  {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    
    return fetch(process.env.React_App_AWS_API+"project-managers/create",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  createProjectUsers(requestJson)
  {
     
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    
    return fetch(process.env.React_App_AWS_API+"project-users/create",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getProjectInfoById(projectId)
  {
      return http.get(`/project/getProjectById/`+projectId);
  }

  getProjectManagersByProjectId(projectid, managerid, projStatus, passignedStatus, clientStatusval){
    return http.get(`/project-managers/getAll?projectId=`+projectid+`&managerId=`+managerid+`&projectStatus=`+projStatus+`&projectAssignedStatus=`+passignedStatus+`&clientStatus=`+clientStatusval);
  }

  deleteReportingManager(reportingManagerIdVal){
    return http.delete(`user-reporting-manager/delete/`+reportingManagerIdVal);
  }

  getClientsByUserId(projectid, companyIdval, useridval, clientStatus, sortingOrder, sortingColumn){
    return http.get(`/client/getAllClientsWithUserId?projectId=`+projectid+`&sortOrder=`+sortingOrder+`&sortBy=`+sortingColumn+`&status=`+clientStatus+`&pageNumber=1&companyId=`+companyIdval+`&pageSize=1000&userId=`+useridval);
  }


  getProjectUsersByProjectId(projectid, userid, pageNumber, pageSize, assignedStat,projectStatusval, clientFilterVal, clientStatus, sortingOrder, sortingColumn){
    if(userid === ''){
      return http.get(`/project-users/getAll?projectId=`+projectid+`&projectStatus=`+projectStatusval+`&pageNumber=`+pageNumber+`&pageSize=&projectAssignedStatus=`+assignedStat+`&clientStatus=`+clientStatus+`&clientId=`+clientFilterVal+`&sortOrder=`+sortingOrder+`&sortBy=`+sortingColumn);
    } else {
      return http.get(`/project-users/getAll?userId=`+userid+`&projectStatus=`+projectStatusval+`&pageNumber=`+pageNumber+`&pageSize=&projectAssignedStatus=`+assignedStat+`&clientStatus=`+clientStatus+`&clientId=`+clientFilterVal+`&sortOrder=`+sortingOrder+`&sortBy=`+sortingColumn);
    }
  }

  // getAllTasksInfo(taskStatus, taskbillable,projectid,searchtext, pagenum, pagesize){
  //   return http.get(`/tasks/getAllTasks?status=`+taskStatus+`&billable=`+taskbillable+`&projectId=`+projectid+`&search=`+searchtext);
  // }

  getAllTasksInfo(requestJson)
  {
      
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    

    return fetch(process.env.React_App_AWS_API+"tasks/getAllTasks",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getTaskHours(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    

    return fetch(process.env.React_App_AWS_API+"tasks/hours",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getTeamMembersWithProjectId(projectid, assignedStatus){
    return http.get(`/project/managers-and-users?projectId=`+projectid+`&projectAssignedStatus=`+assignedStatus);
  }

  getTaskById(taskid) {
    return http.get(`/tasks/getTaskById/`+taskid);
  }

  updateTaskUserswithTaskId(requestJson){
    
    const requestOptions = {
      method: 'PUT',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"task-users/updateByTaskId",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  updateTaskInfoById(requestJson, taskid){
    
    const requestOptions = {
      method: 'PUT',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"tasks/updateById/"+taskid,requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getProjectDetailsFullwithProjectId(projectid){
    return http.get(`/project/getProjectStats?projectId=`+projectid);
  }

  getProjectTeamDetailsFullwithProjectId(projectid, userstatus){
    return http.get(`/users/getUserStats?projectId=`+projectid+`&userStatus=`+userstatus);
  }
  getProjectTeamDetailsFullwithProjectIdAdmin(projectid, userstatus, sortBy,sortOrder, pageNum,pageSize){
    return http.get(`/users/getUserStats?projectId=`+projectid+`&userStatus=`+userstatus+`&sortBy=`+sortBy+`&sortOrder=`+sortOrder+`&pageNumber=`+pageNum+`&pageSize=`+pageSize);
  }

  getTaskCommentsByTaskId(taskid, userid){
    return http.get(`task-user-comment/getAll?taskId=`+taskid+`&userId=`+userid);
  }

  createTaskComment(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    

    return fetch(process.env.React_App_AWS_API+"task-user-comment/create",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getProjectUsersByUserId(userid, projstatus){
    return http.get(`/project-users/getAll?userId=`+userid+`&projectStatus=`+projstatus);
  }

  getTasksforUser(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    

    return fetch(process.env.React_App_AWS_API+"task-users/findAll",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getTaskUserswithTaskId(taskid, userid){
    return http.get(`/task-users/getAll?taskId=`+taskid+`&userId=`+userid);
  }

  getTeamSizewithTaskId(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    

    return fetch(process.env.React_App_AWS_API+"task-users/getTeamSizes",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  updateTimeSheetSatus(requestJson){

    const requestOptions = {
      method: 'PUT',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"time-entries/update-status",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  createTimeSheetComment(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    

    return fetch(process.env.React_App_AWS_API+"week-time-entry-comments/create",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
 
  }

  sendEmailReminder(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    


    return fetch(process.env.React_App_AWS_API+"time-entries/send-reminder-emails",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
 
  }

  getTimesheetComments(startdate, enddate, timresheetuser){
    return http.get(`/week-time-entry-comments/getAll?weekStartDate=`+startdate+`&weekEndDate=`+enddate+`&commentedForTimeSheetUser=`+timresheetuser);
  }

  getUsersList(projectid, userstatus, pagenumber, pageitems, managerid, companyid, sortingorder, sortColumn){
    const sortOrderInput = sortingorder || 'ASC'; 
    const sortByInput = sortColumn || ''; 
    return http.get(`users/related-info?projectId=`+projectid+`&userStatus=`+userstatus+`&pageNumber=`+pagenumber+`&pageSize=`+pageitems+`&managerId=`+managerid+`&companyId=`+companyid+`&sortBy=`+sortByInput+`&sortOrder=`+sortOrderInput);
  }

  getUserDesignations(){
    return http.get(`/designation/getAllDesignations`);
  }

  assignProject(requestJson)
  {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    

    return fetch(process.env.React_App_AWS_API+"project-users/assign",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  assignProjectManager(requestJson)
  {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };      
    
    return fetch(process.env.React_App_AWS_API+"project-managers/assign-managers",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  createUserFromAdmin(requestJson) {
    
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };     

    return fetch(`${process.env.React_App_AWS_API}/users/create`, requestOptions)
      .then(response => response.json())
      .then(responseData => responseData)
      .catch(error => {
        console.error('Error creating user:', error);
        return { name: "network error", description: "" };
      });
  }

  createUserReportingManager(requestJson) {
    
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };     

    return fetch(`${process.env.React_App_AWS_API}/user-reporting-manager/create`, requestOptions)
      .then(response => response.json())
      .then(responseData => responseData)
      .catch(error => {
        console.error('Error creating user:', error);
        return { name: "network error", description: "" };
      });
  }

  getUserById(userid){
    return http.get(`/users/getUserById/`+userid);
  }

  getUsersReportingManager(userid){
    return http.get(`/user-reporting-manager/getAll?userId=`+userid+`&managerId=`);
  }

  updateUserInformation(requestJson, userid) {
      
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };     
      return fetch(process.env.React_App_AWS_API+"users/updateById/"+userid,requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getProjectsByCompany(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };     

    return fetch(process.env.React_App_AWS_API+"project/getProjectsByCompany",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getProjectsFilterbyClient(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };     

    return fetch(process.env.React_App_AWS_API+"/project/filter-by-clients",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getTasksFilterbyProject(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };     

    return fetch(process.env.React_App_AWS_API+"/tasks/filter",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getTasksFilterbyProjectIncCompany(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };     

    return fetch(process.env.React_App_AWS_API+"/tasks/filterIncCompany",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getTasksUsersbyTask(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    

    return fetch(process.env.React_App_AWS_API+"/tasks/fetch-tasks-users",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getTasksUsersbyCompany(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    

    return fetch(process.env.React_App_AWS_API+"/tasks/fetch-tasks-users-by-company",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getReportFilteredTimeEntries(requestJson){

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson),  // Ensure the body is JSON stringified
    };    

    return fetch(process.env.React_App_AWS_API+"time-entries/getFilteredTimeEntries",requestOptions)
      .then(response => { return response.json();})
      .then(responseData => {return responseData;})
      .catch(error => {
        console.error(error);
        return { name: "network error", description: "" };
      });
  }

  getUsersInfoById(userid){
    return http.get(`/users/getUserById/`+userid);
  }

  getAllNotifications(requestJson){

    const requestOptions = {
      method: 'POST',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"notifications/getAllNotifications/",requestOptions)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }

  getAllNotificationsForAdmin(requestJson){

    const requestOptions = {
      method: 'POST',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"notifications/getAllNotificationsForAdmin/",requestOptions)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }

  updateUserProfile(requestJson, userid){

    const requestOptions = {
      method: 'PUT',  // Set method to PUT
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Add accessToken here
      },
      body: JSON.stringify(requestJson)  // Ensure the requestJson is JSON stringified
    };

    return fetch(process.env.React_App_AWS_API+"users/updateById/"+userid,requestOptions)
    .then(response => { return response.json();})
    .then(responseData => {return responseData;})
    .catch(error => {
      console.error(error);
      return { name: "network error", description: "" };
    });
  }
  

}   
 
export default new Service();