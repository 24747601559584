import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import services from "../../../services/services";
import AddClient from "../../modals/add-client";
import AddProject from "../../modals/add-project";
import AddTask from "../../modals/add-task";
import $ from 'jquery';
import Pagination from "../../../utility/Pagination/Pagination";
import { useTable, useSortBy, useExpanded } from 'react-table';
import ProjectManagementHeader from "./project-management-header";

function ConfirmationPopup({ message, onConfirm, onCancel }) {
    return (
        <div className="popup">
            <div className="popup-content">
                <p>{message}</p>
                <button onClick={onConfirm} class="btn btn-primary  me-2">Yes</button>
                <button onClick={onCancel} class="btn btn-outline-dark">No</button>
            </div>
        </div>
    );
}

function ConfirmationPopup2({ message, onConfirm, onCancel }) {
    return (
        <div className="popup">
            <div className="popup-content">
                <p>{message}</p>
                <button onClick={onCancel} class="btn btn-primary  me-2">Ok</button>
                {/* <button onClick={onCancel} class="btn btn-outline-dark">No</button> */}
            </div>
        </div>
    );
}

const ManageProjects = () => {

    const projectTabRef = useRef(null);
    const clientTabRef = useRef(null);
    const taskTabRef = useRef(null);

    const location = useLocation();

    const useridval = useState(sessionStorage.getItem('userIdval'));
    const [loggedUser, setLoggedUser] = useState(sessionStorage.getItem('userRole'));
    const [companyid, setCompanyId] = useState(sessionStorage.getItem('userCompanyId'));
    const [adminCompanyid, setAdminCompanyId] = useState(sessionStorage.getItem('adminCompany'));
    const [clientStatus, setClientStatus] = useState(1);
    const [clientStatusIsChecked, setClientStatusIsChecked] = useState(0);
    const [sortProjectListConfig, setSortProjectListConfig] = useState({ key: '', direction: '' });
    const [clientListInfo, setClientListInfo] = useState([]);
    const [selectedClientIdForEdit, setSelectedClientIdForEdit] = useState(null);
    const [selectedProjectIdForEdit, setSelectedProjectIdForEdit] = useState(null);
    const [selectedTaskIdForEdit, setSelectedTaskIdForEdit] = useState(null);
    const [selectedDynamicValue, setSelectedDynamicValue] = useState(null);
    const [projectStatusIsChecked, setProjectStatusIsChecked] = useState(0);
        const [projectStatus, setProjectStatus] = useState(1);

    const [projectListInfo, setProjectListInfo] = useState([]);

    const pagenum = 1;
    const pagesize = 3;
    const ITEMS_PER_PAGE = 10; // Per page show the data pagination
    //const Pagesize=5;
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [showPopup, setShowPopup] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);

    const [projectIdforUpdate, setProjectIdforUpdate] = useState(null);
    const [projectStatustoUpdate, setProjectStatustoUpdate] = useState(null);

    const [showLoader, setShowLoader] = useState(0);
    const [clientForProject, setClientForProject] = useState('');

    function getClientsList(mycompanyid, clientstatusval, pagenumval, itemsperpage, managerid) {
        setShowLoader(1);
        //alert('79 - '+clientstatusval);
        services.getClientsList(mycompanyid, clientstatusval, pagenumval, itemsperpage, managerid).then((res) => {
            if (res.data.type === 'success') {
                setClientListInfo(res.data.data);
                setTotalItems(res.data.totalRecords);
                settotalPages(Math.ceil(res.data.totalRecords / ITEMS_PER_PAGE));
                setCurrentPage(pagenumval);
            }
            setShowLoader(0);
        });
    }

    const handleProjectPageChange2 = (offset, triggerevent) => {
        setCurrentPage(offset);
    }

    useEffect(() => {
        var clientFilterCompanyVal = '';
        var managerid='';
        var clientlistid = '';
        var clientstatus = '';
        if(loggedUser.toLowerCase() === 'manager'){
            clientFilterCompanyVal = companyid;
        }
        if(loggedUser.toLowerCase() === 'admin'){
            clientFilterCompanyVal = adminCompanyid;
        }
       // alert('106 - '+clientStatus);
        getClientsList(clientFilterCompanyVal, '', 1, 1000, managerid);

        const queryParams = new URLSearchParams(location.search);
        const clientval = queryParams.get('cid');
        const statusVal = queryParams.get('status');
        if(!isEmpty(clientval)){
            // if(statusVal === 1){
            //     getProjects(clientval, 1, 'asc', currentPage, ITEMS_PER_PAGE, managerid, clientstatus, clientFilterCompanyVal);
            // }
            // else {
            //     getProjects(clientval, 0, 'asc', currentPage, ITEMS_PER_PAGE, managerid, clientstatus, clientFilterCompanyVal);
            // }

                getProjects(clientval, projectStatus, 'asc', currentPage, ITEMS_PER_PAGE, managerid, clientstatus, clientFilterCompanyVal);

        }
        setClientForProject(clientval);
        //getProjects(clientForProject, ProjectStatus, 'asc', currentPage, ITEMS_PER_PAGE, managerid, clientstatus, clientFilterCompanyVal);

    }, []);

    useEffect(() => {
        var clientFilterCompanyVal = '';
        var managerid='';
        var clientlistid = '';
        var clientstatus = '';
        if(loggedUser.toLowerCase() === 'manager'){
            clientFilterCompanyVal = companyid;
        }
        if(loggedUser.toLowerCase() === 'admin'){
            clientFilterCompanyVal = adminCompanyid;
        }
        var myclientid = clientForProject;
        if(isEmpty(clientForProject)){
            myclientid = '';
        }
        //getClientsList(clientFilterCompanyVal, clientStatus, 1, 1000, managerid);
        getProjects(myclientid, projectStatus, 'asc', currentPage, ITEMS_PER_PAGE, managerid, clientstatus, clientFilterCompanyVal);

    }, [location, projectStatus, currentPage, clientForProject, sortProjectListConfig]);


    const isEmpty = (value) => {
        return value === null || String(value).trim() === '';
    }

    const handleCancel = () => {
        setShowPopup(false);
    };

    const handleCancel2 = () => {
        setShowPopup2(false);
    }

    const getProjects = async (clientlistid, projectstatusval, sortingorder, pagenumval, itemsperpage, managerid, clientStatus, usercompanyval) => {
        setShowLoader(1);
        setProjectListInfo([]);
        try {
            const res = await services.getProjectsDetailsListAdmin(clientlistid, projectstatusval, sortingorder, pagenumval, itemsperpage, managerid, clientStatus, usercompanyval,sortProjectListConfig.direction,sortProjectListConfig.key);
            if (res.data.type === 'success') {
                setProjectListInfo(res.data.data);
                //setProjectsFromService(res.data.data); // Store the projects from the service if needed
                setTotalItems(res.data.totalRecords);
                settotalPages(Math.ceil(res.data.totalRecords / itemsperpage));
                setCurrentPage(pagenumval);
            }
        } catch (error) {
            console.error('Error fetching project details:', error);
        }
        finally {
            setShowLoader(0);
        }
    };

    const handleProjectStatus = (event) => {
        setCurrentPage(1);
        if (event.target.checked) {
            setProjectStatus(0);
            setProjectStatusIsChecked(1);
        } else {
            setProjectStatus(1);
            setProjectStatusIsChecked(0);
        }
    }

    const handleClientFilter = (event) => {
        setCurrentPage(1);
        var clientfilterselected = event.target.value;
        setClientForProject(clientfilterselected);
    }

    const changeProjectStatus = (projectid, newStatus, clientStatus) => {
        if(clientStatus === 1){
            setProjectIdforUpdate(projectid);
            setProjectStatustoUpdate(newStatus);
            setShowPopup(true);
        } else {
            setShowPopup2(true);
        }

    }

    function formatTimeToHHMM(value) {
        // if (value === '') return '';

        // if (value.length <= 2) {
        //   return value; // Return as is if only two digits or less
        // }

        const time = String(value);
        const hours = time.substring(0, 2).padStart(2, '0');
        const minutes = time.substring(2, 4).padStart(2, '0');
        const seconds = time.substring(4, 6).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };


    const handleUpdateProject = () => {
        var requestObject = {};
        for (var p = 0; p < projectListInfo.length; p++) {
            if (projectListInfo[p].projectId === projectIdforUpdate) {
                var requestObject = {
                    "name": projectListInfo[p].projectName,
                    "code": projectListInfo[p].projectCode,
                    "project_start_date": projectListInfo[p].projectStartDate,
                    "project_end_date": projectListInfo[p].projectEndDate,
                    "budget_time": formatTimeToHHMM(projectListInfo[p].allocatedHours),
                    "client_id": projectListInfo[p].clientId,
                    "project_status": projectStatustoUpdate
                }
            }
        }

        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'PUT',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        $(".load-wrap").show(); //console.log(requestObject); return false;
        setShowLoader(1);
        setShowPopup(false);
        services.updateProjectById(requestObject, projectIdforUpdate).then((res) => {
            //setIsOpen(false);
            //$(".load-wrap").show();
            $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                $("#updatesuccessMessage").slideUp(500);
            });
            setTimeout(function () {
                var managerid = ''; var reqCompany = '';
                if (loggedUser.toLowerCase() === 'manager') {
                    //managerid = sessionStorage.getItem('userIdval');
                    reqCompany = companyid;
                }

                if (loggedUser.toLowerCase() === 'admin') {
                    reqCompany = adminCompanyid;
                }
                setCurrentPage(1);
                if (projectStatustoUpdate === 1) {
                    setProjectStatusIsChecked(0);
                    setProjectStatus(1);
                } else {
                    setProjectStatusIsChecked(1);
                    setProjectStatus(0);
                }
                //getProjects(reqCompany, projectStatustoUpdate, 'asc', pagenum, ITEMS_PER_PAGE, managerid);
            }, 1000);
            setShowLoader(0);
        });
    }

    const createNewProject = () => {
        setSelectedProjectIdForEdit(null);
    }

    const editProject = (projectidtoedit) => {
        setSelectedProjectIdForEdit(projectidtoedit);
    }

    const [formMode, setFormMode] = useState(null);
    const [projectidfortask, setprojectidfortask] = useState(null);
    const createTaskForProject = (projectid) => {
        setFormMode('add');
        setprojectidfortask(projectid);
        setSelectedTaskIdForEdit(null);
    }

    
    const handleProjectListSort = (columnKey) => {
        let direction = 'ASC';
        if (sortProjectListConfig.key === columnKey && sortProjectListConfig.direction === 'ASC') {
            direction = 'DESC';
        }
        setCurrentPage(1);
        setSortProjectListConfig({ key: columnKey, direction });
    }

    const projcolumns = React.useMemo(
        () => {
            const baseColumns = [
                {
                    //Header: 'Project',

                    Header: (
                        <span onClick={() => handleProjectListSort('project.name')}>
                            Project{sortProjectListConfig.key === 'project.name' ? (sortProjectListConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                        </span>
                    ),

                    accessor: 'projectName',
                    disableSortBy: true,
                    width: 'auto',
                    Cell: ({ value, row }) => (
                        <a href={`/projectDetail?id=${row.original.projectId}`}>{value}</a>
                       /* loggedUser.toLowerCase() === 'manager' ? (
                            (row.original.projectManagerStatus && row.original.projectManagerStatus[0] === 'assigned') ? (
                                <a href={`/projectDetail?id=${row.original.projectId}`}>{value}</a>
                            ) : (
                                <>{value} <div>
                                    <small class="badge rounded-pill bg-success-orange mx-2 font-500 px-2 py-1">Unassigned</small>
                                </div></>  // Render the value inside React fragment when no link is required
                            )
                        ) : (
                            <a href={`/projectDetail?id=${row.original.projectId}`}>{value}</a>
                        ) */

                    ),
                    sortType: 'basic',  // Enable sorting for this column
                },
                {
                    //Header: 'Client',
                    Header: (
                        <span onClick={() => handleProjectListSort('client.client_name')}>
                            Client{sortProjectListConfig.key === 'client.client_name' ? (sortProjectListConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                        </span>
                    ),
                    accessor: 'clientName',
                    disableSortBy: true,
                    width: 'auto',
                    Cell: ({ value }) => (
                        <div>{value}</div>
                    ),
                    sortType: 'basic',  // Enable sorting for this column
                },
                {
                    Header: 'Estimated Hrs',
                    accessor: 'allocatedHours',
                    width: 'auto',
                    // Cell: ({ value }) => (
                    //     <div>{parseInt(value) > 0 ? value.toFixed(2) : ''}</div>
                    // ),
                    Cell: ({ value }) => (
                        <div>
                            {/* {isNaN(value) || value === null || value === undefined ? '' : parseFloat(value).toFixed(2)} */}
                            { value === null || value === undefined || value == '00:00' ? '' : value} 
                        </div>
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                    disableSortBy: true,
                },
                {
                    Header: 'Spent Hrs',
                    accessor: 'burnedHours',
                    disableSortBy: true,
                    width: 'auto',
                    Cell: ({ row }) => (
                        <div>
                            {/*{(parseInt(row.original.allocatedHours) > 0 && parseInt(row.original.burnedHours) > parseInt(row.original.allocatedHours)) ? (
                                <>
                                    {parseInt(row.original.allocatedHours)}
                                    <span className="extrahr">
                                        +{parseInt(row.original.burnedHours) - parseInt(row.original.allocatedHours)}
                                    </span>
                                </>
                            ) : (
                                parseInt(row.original.burnedHours) > 0 ? (row.original.burnedHours).toFixed(2) : ''
                            )} */}
                            {/* {(parseInt(row.original.burnedHours) > 0 ? (row.original.burnedHours).toFixed(2) : '')} */}
                            {(parseInt(row.original.burnedHours) > 0 ? parseFloat(row.original.burnedHours).toFixed(2) : '')}
                        </div>
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                },
                {
                    Header: 'Balance Hrs',
                    accessor: 'remainingHours',
                    disableSortBy: true,
                    width: 'auto',
                    Cell: ({ value }) => (
                        <div>
                            {/* {parseInt(value) > 0 ? value.toFixed(2) : ''} */}
                            {/* {isNaN(value) || value === null || value === undefined ? '' : parseFloat(value).toFixed(2)} */}
                            {value === null || value === undefined || value == 0.00 || parseInt(value) < 0 ? '' : parseFloat(value).toFixed(2)}
                        </div>
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                },
                {
                   // Header: 'Status',
                   Header: (
                    <span onClick={() => handleProjectListSort('project.project_status')}>
                        Status{sortProjectListConfig.key === 'project.project_status' ? (sortProjectListConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                    </span>
                ),
                    accessor: 'projectStatus',
                    width: 'auto',
                    disableSortBy: true,
                    Cell: ({ value }) => (
                        <>
                            {value === 1 ? (
                                <>
                                    <img src="images/active.svg" alt="Active" /> Active
                                </>
                            ) : (
                                <>
                                    <img src="images/redcheck.svg" width={15} alt="Inactive" /> Inactive
                                </>
                            )}
                        </>
                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                },
                // {
                //     Header: 'Team Size',
                //     accessor: 'teamSize',
                //     width: 'auto',
                //     Cell: ({ value }) => (
                //         <div>{value}</div>
                //     ),
                //     sortType: 'basic', // Enable sorting for Task Name
                // },
            ];

            baseColumns.push(
                {
                    Header: 'Actions',
                    accessor: 'actions',
                    width: 'auto',
                    disableSortBy: true,
                    Cell: ({ row }) => (

                        <div className="dropdown table-action-dd text-left">
                                <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="images/dots.svg" alt="" />
                                </button>
                                <ul className="dropdown-menu">
                                {row.original.projectStatus === 1 ? (
                                    <>
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalcp" onClick={() => editProject(row.original.projectId)}>
                                            <img src="images/edit.png" alt="" /> Edit Project
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => createTaskForProject(row.original.projectId)}>
                                            <img src="images/add.svg" width={19} alt="" /> Create Task(s)
                                        </a>
                                    </li>
                                    <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeProjectStatus(row.original.projectId, 0,row.original.clientStatus)}>
                                    <img src="images/disabled.svg" width={18} alt="" /> Disable
                                                </a>
                                    </li></>
                                ) : <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeProjectStatus(row.original.projectId, 1,row.original.clientStatus)}>
                                                    <img src="images/greencheck.svg" width={19} alt="" /> Enable
                                                </a>
                                    </li>}
                                </ul>
                            </div>
                        /*
                        loggedUser.toLowerCase() === 'manager' ? (
                            (row.original.projectManagerStatus && row.original.projectManagerStatus[0] === 'assigned') ? (
                                <div className="dropdown table-action-dd text-left">
                                    <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="images/dots.svg" alt="" />
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalcp" onClick={() => editProject(row.original.projectId)}>
                                                <img src="images/edit.png" alt="" /> Edit Project
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => createTaskForProject(row.original.projectId)}>
                                                <img src="images/add.svg" alt="" /> Create Task(s)
                                            </a>
                                        </li>
                                        {(loggedUser.toLowerCase() === 'admin') && (
                                            <li>
                                                {row.original.projectStatus === 1 ? (
                                                    <a className="dropdown-item" href="#" onClick={() => changeProjectStatus(row.original.projectId, 0)}>
                                                        <img src="images/delete.svg" alt="" /> Disable
                                                    </a>
                                                ) : (
                                                    <a className="dropdown-item" href="#" onClick={() => changeProjectStatus(row.original.projectId, 1)}>
                                                        <img src="images/greencheck.svg" alt="" /> Enable
                                                    </a>
                                                )}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            ) : (
                                <>N/A</>  // Render the value inside React fragment when no link is required
                            )
                        ) : (
                            <div className="dropdown table-action-dd text-left">
                                <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="images/dots.svg" alt="" />
                                </button>
                                <ul className="dropdown-menu">
                                {row.original.projectStatus === 1 ? (
                                    <>
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalcp" onClick={() => editProject(row.original.projectId)}>
                                            <img src="images/edit.png" alt="" /> Edit Project
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => createTaskForProject(row.original.projectId)}>
                                            <img src="images/add.svg" alt="" /> Create Task(s)
                                        </a>
                                    </li>
                                    <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeProjectStatus(row.original.projectId, 0)}>
                                                    <img src="images/delete.svg" alt="" /> Disable
                                                </a>
                                    </li></>
                                ) : <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeProjectStatus(row.original.projectId, 1)}>
                                                    <img src="images/greencheck.svg" alt="" /> Enable
                                                </a>
                                    </li>}
                                </ul>
                            </div>
                        )
*/

                    ),
                    sortType: 'basic', // Enable sorting for Task Name
                    disableSortBy: true,
                }
            );

            return baseColumns;
        },
        [loggedUser,sortProjectListConfig] // Add loggedUser as a dependency
    );

    const {
        getTableProps: getProjectTableProps,
        getTableBodyProps: getProjectTableBodyProps,
        headerGroups: projectHeaderGroups,
        rows: projectRows,
        prepareRow: prepareProjectRow,
    } = useTable(
        {
            columns: projcolumns,
            data: projectListInfo,
        },
        useSortBy
    );

    return (
        <>
            <main>


                <section>
                    <div class="container">
                        <h2 class="mainHeadline">
                            Project Management
                        </h2>

                        <div class="tab-primary">
                        <ProjectManagementHeader />

                            <div class="tab-content ">

                                <div id="two" class="container tab-pane px-0 fade active show" role="tabpanel">
                                    <div class="d-flex align-items-center justify-content-between pt-2 pb-2">
                                        {/*{(loggedUser.toLowerCase() === 'admin')? */}
                                        <div class="col-lg-3">
                                            <button class="btn btn-primary m-0" data-bs-toggle="modal" data-bs-target="#exampleModalcp" onClick={() => createNewProject()}><span class="mx-2">+</span> Create Project</button>

                                        </div>
                                        {/*}: ''} */}
                                        <div class="d-flex justify-content-end gap-4 align-items-center w-100">
                                            <div class="d-flex justify-content-end align-items-center  ">
                                                <span style={{ "min-width": "69px" }}>Clients:</span>
                                                <select class="form-select" id="clientdropdown" onChange={handleClientFilter} value={clientForProject} style={{ "max-width": "200px" }}>
                                                    <option value="" selected>All</option>
                                                    {clientListInfo.map((data, index) => (
                                                        <option value={data.id} >{data.client_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div class="d-flex justify-content-end align-items-center ">
                                                <span>Status:</span>
                                                <div class="d-flex align-items-center gap-2 py-2 ps-2 form-switch">
                                                    <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Active</small></b></label>
                                                    <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="projectStatusToggle" onChange={handleProjectStatus} checked={projectStatusIsChecked} />
                                                    <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Inactive</small></b></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tbl-container bdr mt-0">

                                        <table {...getProjectTableProps()} className="table mb-0">
                                            <thead className="bg-lightblue">
                                                {projectHeaderGroups.map(headerGroup => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map(column => (
                                                            <th
                                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                style={{
                                                                    width: column.width,
                                                                    position: 'relative',

                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {column.render('Header')}
                                                                <span
                                                                    style={{
                                                                        //position: 'absolute',
                                                                        paddingLeft: '10px',
                                                                        right: '10px',
                                                                        top: '50%',
                                                                        //transform: 'translateY(-50%)',
                                                                        display: 'inline-block',
                                                                        fontSize: '0.8em',
                                                                        color: '#888',
                                                                    }}
                                                                >
                                                                    {
                                                                        !column.disableSortBy ? (
                                                                            column.isSorted ? (
                                                                                column.isSortedDesc ? ' 🔽' : ' 🔼'
                                                                            ) : '↕️'
                                                                        ) : null
                                                                    }
                                                                </span>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getProjectTableBodyProps()}>
                                                {projectRows.length > 0 ? (
                                                    projectRows.map(row => {
                                                        prepareProjectRow(row);
                                                        return (
                                                            <tr {...row.getRowProps()}>
                                                                {row.cells.map(cell => (
                                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                                ))}
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div className="d-grid text-center py-3">
                                                                <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                                                <span> No projects available.</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>


                                    </div>
                                    {showPopup && (
                                        <ConfirmationPopup
                                            message="Are you sure you want to Update this Project?"
                                            onConfirm={handleUpdateProject}
                                            onCancel={handleCancel}
                                        />
                                    )}

{showPopup2 && (
                                        <ConfirmationPopup2
                                            message="Please enable the client before activating the project."
                                            // onConfirm={handleUpdateProject}
                                            onCancel={handleCancel2}
                                        />
                                    )}

                                    <div class="paginationContent mt-3">
                                        <Pagination
                                            total={totalItems}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage}
                                            onPageChange={handleProjectPageChange2} />

                                    </div>
                                </div>
                            </div>
                            {showLoader === 1 ?
                                <div class="loadwrapper">
                                    <div class="clock"><div class="circle"></div></div>
                                </div>
                                : ''}
                        </div>

                    </div>

                    <AddProject editProject={selectedProjectIdForEdit} />

                    <AddTask formMode={formMode} taskProject={projectidfortask} editTask={selectedTaskIdForEdit} dynamic={selectedDynamicValue} />

                    <AddClient editClient={selectedClientIdForEdit} isOpenModal='1' />






                </section>

            </main>

        </>
    )
};

ManageProjects.propTypes = {};

ManageProjects.defaultProps = {};

export default ManageProjects;
