import React,{history,useEffect, useState, useMemo, useRef  } from "react";
import { useNavigate } from 'react-router-dom';
import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import DateObject from "react-date-object";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
        <div>
            {
                //console.log("results=="+JSON.stringify(props))
            }
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


const AddTask = ({formMode, taskProject, editTask,dynamic}) => {
    const navigate = useNavigate();
    const modalRef = useRef(null); // Reference to the modal
    const closeButtonRef = useRef(null); // Reference to the close button
    const [showProjectList, setShowProjectList] = useState([]);
    const [showProjectAssignedUsersList, setShowProjectAssignedUsersList] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [clientName, setclientName] = useState('');
    const [initialClientName, setInitialClientName] = useState('');
    const [clientCompany, setClientCompany] = useState('');
    const [initialCompany, setInitialCompany] = useState('');
    const [showTaskInfo, setShowTaskInfo] = useState('');

    const [isBillablect, setIsBillablect] = useState(0);
    const [taskinputDurationValue, setTaskInputDurationValue] = useState('');
    const [formattedTaskTime, setFormattedTaskTime] = useState('');
    const [taskprojvalidation, setTaskProjvalidation] = useState(0);
    const [tasknamevalidation, setTaskNamevalidation] = useState(0);
    const [taskteamvalidation, setTaskteamvalidation] = useState(0);
    const [newtaskdurationvalidation, setNewTaskdurationvalidation] = useState(0);
    const [edittaskprojvalidation, setEditTaskProjvalidation] = useState(0);
    const [newTaskAssignedvalidation, setNewTaskAssignedvalidation] = useState(0);
    const [edittasknamevalidation, setEditTaskNamevalidation] = useState(0);
    const [edittaskdurationvalidation, setEditTaskdurationvalidation] = useState(0);
    const [taskStatusonEdit, setTaskStatus] = useState('');

    const [multiProjectAssignedUsers, setmultiProjectAssignedUsers] = useState([]);
    const pageNum = 1;
    const pageSize = 100;

    const useridval = useState(sessionStorage.getItem('userIdval'));
    const [loggedUser, setLoggedUser] = useState(sessionStorage.getItem('userRole'));
    const [companyid, setCompanyId] = useState(sessionStorage.getItem('userCompanyId'));
    const [adminCompanyid, setAdminCompanyId] = useState(sessionStorage.getItem('adminCompany'));
const [loadSpinner, setLoadSpinner] = useState(0);

    ///////////////////////////
    const [timeValue, setTimeValue] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
    
        // Allow only numbers and colon
        if (/^[0-9:]*$/.test(value)) {
          // Split the value into hours and minutes if a colon exists
          let [hours = "", minutes = ""] = value.split(":");
    
          // Limit hours to 4 digits and minutes to 2 digits
          if (hours.length <= 4 && minutes.length <= 2) {
            // Check if minutes are valid (less than or equal to 59)
            if (minutes === "" || parseInt(minutes) <= 59) {
              setTimeValue(value);
            }
          }
        }
      };
    
      const handleBlur = () => {
        // Ensure the value is formatted as 0000:00 on blur
        const [hours = "", minutes = ""] = timeValue.split(":");
        /*
        // Pad hours and minutes with zeros
        const paddedHours = hours.padStart(4, "0");
        const paddedMinutes = minutes.padStart(2, "0");
    
        setTimeValue(`${paddedHours}:${paddedMinutes}`);
        */

        // Pad hours and minutes with zeros
        const paddedHours = hours.padStart(4, "0");
        const paddedHoursInt = parseInt(paddedHours);
        const paddedMinutes = minutes.padStart(2, "0");
        const paddedMinutesInt = parseInt(paddedMinutes);
    
       // setEditTimeValue(`${paddedHours}:${paddedMinutes}`);
       setTimeValue(`${paddedHoursInt}:${paddedMinutes}`);
      };
    
      const handleFocus = () => {
        // Clear the input value on focus
        //setTimeValue("");
      };
    
      const handleKeyDown = (e) => {
        // Optionally, you can prevent special characters or handle other key events here
      };
    //////////////////////////


    ////
    const [editTimeValue, setEditTimeValue] = useState("");

    const handleChangeEdit = (e) => {
        const value = e.target.value;
    
        // Allow only numbers and colon
        if (/^[0-9:]*$/.test(value)) {
          // Split the value into hours and minutes if a colon exists
          let [hours = "", minutes = ""] = value.split(":");
    
          // Limit hours to 4 digits and minutes to 2 digits
          if (hours.length <= 4 && minutes.length <= 2) {
            // Check if minutes are valid (less than or equal to 59)
            if (minutes === "" || parseInt(minutes) <= 59) {
              setEditTimeValue(value);
            }
          }
        }
      };

      const handleBlurEdit = () => {
        // Ensure the value is formatted as 0000:00 on blur
        const [hours = "", minutes = ""] = editTimeValue.split(":");
    
        // Pad hours and minutes with zeros
        const paddedHours = hours.padStart(4, "0");
        const paddedHoursInt = parseInt(paddedHours);
        const paddedMinutes = minutes.padStart(2, "0");
        const paddedMinutesInt = parseInt(paddedMinutes);
    
       // setEditTimeValue(`${paddedHours}:${paddedMinutes}`);
       setEditTimeValue(`${paddedHoursInt}:${paddedMinutes}`);
      };


      const handleKeyDownEdit = (e) => {
        // Optionally, you can prevent special characters or handle other key events here
      };

      const handleFocusEdit = () => {
        // Clear the input value on focus
        //setEditTimeValue("");
      };
    

    //



   

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all",mode: "onBlur"
    });

    
    useEffect(() => {
        // Attach the event listener to the modal
        const modalElement = document.getElementById('exampleModalct');
        const handleModalHidden = () => resetForm();
        
        modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    
        return () => {
            // Cleanup the event listener when the component unmounts
            modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, []);
    
    useEffect(() => {
        if (!isOpen) {
            resetForm(); // Reset the form when the modal is closed
        }
    }, [isOpen]);
    
    const resetForm = () => {
        //alert("reset 159");
        //$("#entryform1").trigger("reset");
        setTaskInputDurationValue('');
        setFormattedTaskTime('');
        setTaskProjvalidation(0);
        setTaskNamevalidation(0);
        setTaskteamvalidation(0);        
        setNewTaskdurationvalidation(0);  
        setEditTaskProjvalidation(0); 
        setNewTaskAssignedvalidation(0); 
        
        setEditTaskNamevalidation(0); 
        setEditTaskdurationvalidation(0); 
        setProjectSelected("");
	    setoptionAssignedUsersSelected([])
        setTaskStatus(''); 
        $('#successMessage').hide();
        $('#updatesuccessMessage').hide();
        $('#failuresuccessMessage').hide();
        $('#validationMessage').hide();
        $('#editvalidationMessage').hide();
     };

    useEffect(() => {
        getProjectList('',1,'asc', pageNum,pageSize);
        //alert(editTask)
        if(!isEmpty(editTask)){
            //edit
            $('#taskaddbody').hide();
            $('#taskeditbody').show();
            console.log(editTask);
            getTaskInfo(editTask);
        }
        else {
            resetForm()
            if(!isEmpty(taskProject)){
                setProjectSelected(taskProject);
                getTeamDetailsWithProjectId(taskProject);
            }
            $('#taskaddbody').show();
            $('#taskeditbody').hide();
        }
        $('#successMessage').hide();
        $('#updatesuccessMessage').hide();
        $('#failuresuccessMessage').hide();
        $('#validationMessage').hide();
        $('#editvalidationMessage').hide();
    },[editTask, taskProject, dynamic]);

    function getProjectList(cientlistid, projectstatusval, sortingorder,pagenum, pagesize){
        var projectstatus = 1;
        var managerid = ''; 
        if(loggedUser.toLowerCase() === 'manager'){
            managerid = sessionStorage.getItem('userIdval');
        }

        var reqCompanyVal = '';
        //console.log(sessionStorage.getItem('userRole'));
        if(sessionStorage.getItem('userRole') == 'Manager'){
            //managerid = sessionStorage.getItem('userIdval');
            reqCompanyVal = companyid;
        }

        if(sessionStorage.getItem('userRole') == 'Admin'){
            reqCompanyVal = adminCompanyid;
        }
         
        
        services.getProjectsDetailsList(cientlistid, projectstatusval, sortingorder,pagenum, pagesize, managerid, 1, reqCompanyVal).then((res) => {
                if(res.data.data != null){
                    setShowProjectList(res.data.data);
                }
        });
    }

    const [isbillablecheck, setisbillablecheck]  = useState(1);
    function getTaskInfo(taskid) {
        services.getTaskById(taskid).then((res) => {
            console.log(res.data);
            if(res){
                if(res.data.data != null){
                    setShowTaskInfo(res.data.data);
                    setEditTextValue(res.data.description);
                    setProjectSelected(res.data.data.project_id);
                    getTeamDetailsWithProjectId(res.data.data.project_id);
                    setFormattedTaskTime(res.data.data.estimated_hours);
                    //$('#edittaskdurationct').val((res.data.data.estimated_hours).slice(0, -3));
                   // $('#edittaskdurationct').val(res.data.data.estimated_hours);
                   setEditTimeValue((res.data.data.estimated_hours).slice(0, -3));
                  // alert(res.data.data.task_status)
                    setTaskStatus(res.data.data.task_status);
                    if(res.data.data.is_billable == 1){
                        setIsBillablect(1);
                    }
                    getTaskUserswithTaskId(res.data.data.id, '');
                }
            }
        });
    }

    function getTaskUserswithTaskId(taskid, userid){
        const requestObject = {
            "userId": userid,
            "taskId": [taskid],
            "projectIds": "",
            "taskUserStatus": "",
            "taskStatus": "",
            "clientId":"",
            "sortOrder":"ASC",
            "sortBy":"TaskStatus"
          }
        console.log(requestObject); //return false;
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getTasksforUser(requestObject).then((res) => {
            console.log(res);
            if(res.type == 'success'){
                if(res.data != null){
                    var dummArray_default = [];
                    var dummArray_ids = [];
                    var teamInfo = res.data;
                    if(!isEmpty(teamInfo)){
                        for (var i = 0; i < teamInfo.length; i++) {
                            if(teamInfo[i].task_user_status === "assigned"){
                                dummArray_default.push({"label": teamInfo[i].user.first_name+' '+teamInfo[i].user.last_name, "value": teamInfo[i].user.id});
                                dummArray_ids.push(teamInfo[i].user.id);
                            }  
                        }
                    } else {
                        dummArray_default.push({"label": 'All', "value": 'all'});
                            dummArray_ids.push('all');
                    }
                    setAssignedUsersRequestArray(dummArray_ids);
                    setoptionAssignedUsersSelected(dummArray_default);
                }
            }
        });
        // services.getTaskUserswithTaskId(taskid, userid).then((res) => {
        //     if(res){

        //         var dummArray_default = [];
        //         var dummArray_ids = [];
        //         var teamInfo = res.data.data;
        //         if(!isEmpty(teamInfo)){
        //             for (var i = 0; i < teamInfo.length; i++) {
        //                 dummArray_default.push({"label": teamInfo[i].user.first_name+' '+teamInfo[i].user.last_name, "value": teamInfo[i].user.id});
        //                 dummArray_ids.push(teamInfo[i].user.id);
        //             }
        //         } else {
        //             dummArray_default.push({"label": 'All', "value": 'all'});
        //                 dummArray_ids.push('all');
        //         }

        //         //console.log(dummArray_default);
        //         setAssignedUsersRequestArray(dummArray_ids);
        //         setoptionAssignedUsersSelected(dummArray_default);
        //     }
        // });
    }

    const [showValidation, setShowValidation] = useState(0);
    const submitValue=()=>
    {
        if(!isEmpty(editTask)){
            //Edit form
            var validation = 0;
            if(isEmpty($('#edittaskName').val())){
                validation = 1;
                setEditTaskNamevalidation(1);
            } else {
                setEditTaskNamevalidation(0);
            }

            if(isEmpty(projectSelected)){
                validation = 1;
                setEditTaskProjvalidation(1);
            } else {
                setEditTaskProjvalidation(0);
            }

             /*if(isEmpty($('#edittaskdurationct').val())){
                validation = 1;
                setEditTaskdurationvalidation(1);
            } else {
                setEditTaskdurationvalidation(0);
            } */

            if(validation == 1){
                $('#editvalidationMessage').show();
                setShowValidation(1);
                return false;
            } else {
                setLoadSpinner(1);
                setShowValidation(0);
                var durationval = $('#edittaskdurationct').val();
                if(isEmpty(durationval)){
                    durationval = '0000:00';
                }
                    //direct update
                    const requestObject = {
                        "name": $('#edittaskName').val(),
                        "project_id":projectSelected,
                        "sprint": $('#edittask_sprint').val(),
                        "description": $('#edittaskdescriptionct').val(),
                        "duration": "00:00:00",
                        "estimated_hours": $('#edittaskdurationct').val(),
                        "is_billable": isBillablect,
                        "task_status": taskStatusonEdit,
                        "task_started_time": new Date(),
                        "task_ended_time": new Date()
                    }
                    var accessTokenIn=sessionStorage.getItem("accToken");
                    // const requestOptions = {
                    //     method: 'PUT',
                    //     headers: {
                    //     'Content-Type': 'application/json',
                    //     'Authorization': "Bearer "+accessTokenIn
                    //     },
                    //     body: JSON.stringify(requestObject)
                    // };

                    $(".load-wrap").show(); //console.log(requestObject); return false;
                    services.updateTaskInfoById(requestObject, editTask).then((res) => {
                        if(res.type == 'success'){
                            var userids = [];

                            for(var m=0; m<optionAssignedUsersSelected.length; m++){
                                if(optionAssignedUsersSelected[m].value == 'all'){
                                    for (var a=0; a< showProjectAssignedUsersList.length; a++){
                                        userids.push(showProjectAssignedUsersList[a].id);
                                    }
                                } else {
                                    userids.push(optionAssignedUsersSelected[m].value);
                                }
                            }
                            const requestObject = {
                                "task_id": editTask,
                                "user_ids": userids
                            }
                            var accessTokenIn=sessionStorage.getItem("accToken");
                            // const requestOptions = {
                            //     method: 'PUT',
                            //     headers: {
                            //     'Content-Type': 'application/json',
                            //     'Authorization': "Bearer "+accessTokenIn
                            //     },
                            //     body: JSON.stringify(requestObject)
                            // };
                            services.updateTaskUserswithTaskId(requestObject).then((res) => {
                                setIsOpen(false);
                                $("#entryform").trigger("reset");
                                $(".load-wrap").show();
                                $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                                    $("#updatesuccessMessage").slideUp(500);
                                });
                                setTimeout(function() { $('#closemodal').click();
                                    var tab = getParameterByName('tab');
                                    console.log(tab);
                                    if(!isEmpty(tab)){
                                        //window.location.href='/project-management?tab=task';
                                        
                                        resetForm()
                                        if (closeButtonRef.current) {
                                            closeButtonRef.current.click();
                                          }
                                       // navigate('/project-management?tab=task');
                                        setLoadSpinner(0);

                                        window.location.href='/managetasks';
                                        
                                       
                                    }
                                    else{
                                        if(window.location.pathname === '/projectDetail'){
                                            ///window.location.href='/projectDetail?id='+projectSelected;
                                            resetForm();

                                            if (closeButtonRef.current) {
                                                closeButtonRef.current.click();
                                            }
                                            //navigate(`/projectDetail?id=${projectSelected}`);
                                            setLoadSpinner(0);
                                            window.location.href = '/projectDetail?id=' + projectSelected;
                                        } else {
                                            resetForm()
                                            if (closeButtonRef.current) {
                                                closeButtonRef.current.click();
                                              }
                                           // navigate('/project-management?tab=task');
                                            
                                           setLoadSpinner(0);
                                            window.location.href='/managetasks';
                                            
                                        } 
                                    }
                                     }, 1000);
                            });
                        }
                    });
            }
        }
        else {
            //add form
            var validation = 0;
            if(isEmpty($('#taskName').val())){
                validation = 1;
                setTaskNamevalidation(1);
            } else {
                setTaskNamevalidation(0);
            }

            console.log(projectSelected);
            if(isEmpty(projectSelected)){
                validation = 1;
                setTaskProjvalidation(1);
            } else {
                setTaskProjvalidation(0);
            }

           /*if(isEmpty($('#taskdurationct').val())){
                validation = 1;
                setNewTaskdurationvalidation(1);
            } else {
                setNewTaskdurationvalidation(0);
            }  */

            if(optionAssignedUsersSelected.length > 0){
                setNewTaskAssignedvalidation(1);
            } else {
                validation = 1;
                setNewTaskAssignedvalidation(1);
            }

            if(validation == 1){
                $('#validationMessage').show();
                setShowValidation(1);
                return false;
            } else {
                setLoadSpinner(1);
                setShowValidation(0);
                var mytaskduration = "00:00";
                var mytaskdateval = [];
                var mytaskdurationval = [];
                var entry_created_from_day_or_week= '';

                var mytaskduration = $('#taskdurationct').val();
                if(isEmpty(mytaskduration)){
                    mytaskduration = '0000:00';
                }
                var dateobjectval = new DateObject(new Date());
                mytaskdateval.push(dateobjectval.format("YYYY-MM-DD"));
                mytaskdurationval.push(mytaskduration);
                entry_created_from_day_or_week = 'day';
                const requestObject =
                {
                    "name": $('#taskName').val(),
                    "project_id": projectSelected,
                    "sprint": $('#task_sprint').val(),
                    "description": $('#taskdescriptionct').val(),
                    "duration": "00:00:00",
                    "estimated_hours": mytaskduration,
                    "is_billable": isbillablecheck,
                    "task_status": "New",
                    "task_started_time": new Date(),
                    "task_ended_time": new Date()
                }
                console.log(requestObject); //return false;
                var accessTokenIn=sessionStorage.getItem("accToken");
                // const requestOptions = {
                //   method: 'POST',
                //   headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                //   },
                //   body: JSON.stringify(requestObject)
                // };

                $(".load-wrap").show();
                services.createNewTask(requestObject).then((res) => {
                    console.log(res.type);
                    if(res.type == 'success'){

                        var userids = [];

                        for(var m=0; m<optionAssignedUsersSelected.length; m++){
                            if(optionAssignedUsersSelected[m].value == 'all'){
                                for (var a=0; a< showProjectAssignedUsersList.length; a++){
                                    userids.push(showProjectAssignedUsersList[a].id);
                                }
                            } else {
                                userids.push(optionAssignedUsersSelected[m].value);
                            }
                        }

                        var resultdata = res.data;
                        console.log(resultdata.id);
                        const requestObject1 =
                        {
                            "task_id": resultdata.id,
                            "user_ids": userids,
                            "task_user_status": "assigned"
                        }
                        console.log(requestObject1);
                        var accessTokenIn=sessionStorage.getItem("accToken");
                        // const requestOptions1 = {
                        //     method: 'POST',
                        //     headers: {
                        //     'Content-Type': 'application/json',
                        //     'Authorization': "Bearer "+accessTokenIn
                        //     },
                        //     body: JSON.stringify(requestObject1)
                        // };

                        services.assignTasktoUser(requestObject1).then((res2) => {
                            if(res2.type == 'success'){
                                $("#entryform").trigger("reset");
                                setIsOpen(false);
                                $(".load-wrap").show();
                                $("#successMessage").fadeTo(2000, 500).slideUp(500, function () {
                                    $("#successMessage").slideUp(500);
                                });

                                setLoadSpinner(0);

                                setTimeout(function() { $('#closemodal').click();
                                window.location.href='/managetasks';
                                }, 1000);
                            }
                        });
                    }
                    if(res.type=='error'){
                        $("#entryform").trigger("reset");
                        setIsOpen(false);
                        $("#failuresuccessMessage").html(res.message);
                        $(".load-wrap").show();
                        $("#failuresuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                            $("#failuresuccessMessage").slideUp(500);
                        });
                        setTimeout(function() { $('#closemodal').click();
                        window.location.href='/managetasks';
                        }, 1000);
                    }
                });
            }
        }

    }

    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        var results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
      }
      const getTitleHead = () => {
        //$("#entryform").trigger("reset");
        if(!isEmpty(editTask)){
            return 'Edit Task';
        }
        else {
            return 'Add New Task';
        }
      }

      const handleCompany = (event) => {
        setClientCompany(event.target.value);
    }

    const [projectSelected, setProjectSelected] = useState("");
    const handleProjectChange = (event) => {
        var projectidval = event.target.value;
        setProjectSelected(projectidval);
        getTeamDetailsWithProjectId(projectidval);
    }
    let teamMembersArray = '';
    const getTeamDetailsWithProjectId = (projectidval) => {
        services.getTeamMembersWithProjectId(projectidval).then((res) => {
            if(res){
                console.log(res.data.data);
                var dummArray = [{ "label": "All", "value": "all" }];
                var dummArray_all = [];
                for (var i = 0; i < res.data.data.length; i++) {
                    dummArray.push({ "label": res.data.data[i].name, "value": res.data.data[i].id });
                    dummArray_all.push({ "label": res.data.data[i].name, "value": res.data.data[i].id });
                }
                console.log(dummArray);
                setmultiProjectAssignedUsers(dummArray);
                setShowProjectAssignedUsersList(res.data.data);
                setoptionAssignedUsersSelected(dummArray_all);

            }
        });
    }

    const [assignedUsersRequestArray, setAssignedUsersRequestArray] = useState([]);
    const [optionAssignedUsersSelected, setoptionAssignedUsersSelected] = useState([]);

    const handleChangeForAssignedUser1 = (selectedOptions) => {
        if (selectedOptions.some(option => option.value === 'all')) {
            // If "All" is selected, select/deselect all options
            if (optionAssignedUsersSelected.length === multiProjectAssignedUsers.length) {
                setoptionAssignedUsersSelected([]); // Deselect all if all are selected
            } else {
                setoptionAssignedUsersSelected(multiProjectAssignedUsers); // Select all
            }
        } else {
            console.log(selectedOptions);
            setoptionAssignedUsersSelected(selectedOptions); // Update based on current selection
        }
    };

    const handleChangeForAssignedUser = (selected) => {

       //alert(JSON.stringify(selected))
        if (selected.some(option => option.value === 'all')) {
            // If "All" is selected, set all options as selected
            setoptionAssignedUsersSelected(multiProjectAssignedUsers);
        } else {
            // If "All" is not selected, update with selected options
            setoptionAssignedUsersSelected(selected);
        }


        var allIndex = '';
        for(var i=0 ; i< selected.length; i++){
            if(selected[i].label == 'All' || selected[i].label == 'all'){
               // allIndex = i;
                allIndex = 0;
            }
        }
        //alert(allIndex);
        var dummArray = [];
        var dummArray_label = [];
        if(!isEmpty(allIndex)){
            if(allIndex == 0){
                
                multiProjectAssignedUsers.splice(allIndex, 1);
                for (var i = 0; i < multiProjectAssignedUsers.length; i++) {
                    dummArray.push(multiProjectAssignedUsers[i].value.toString());
                    dummArray_label.push(multiProjectAssignedUsers[i].label.toString());
                }
                setAssignedUsersRequestArray(dummArray);
                //setoptionAssignedUsersSelected(selected);
            }
            else {
                var mynewSelected = selected[allIndex];
                var dummArray_vals =[];
                for (var i = 0; i < showProjectAssignedUsersList.length; i++) {
                    dummArray.push({ "label": showProjectAssignedUsersList[i].name, "value": showProjectAssignedUsersList[i].id });

                    dummArray_vals.push(showProjectAssignedUsersList[i].id);
                    
                }
                // setmultiProjectAssignedUsers(dummArray);
                // setShowProjectAssignedUsersList(res.data.data);
                // setoptionAssignedUsersSelected(dummArray_all);
                // dummArray.push('all');
                // dummArray_label.push('All');
                setAssignedUsersRequestArray(dummArray_vals);
                //setoptionAssignedUsersSelected(dummArray);
            }
        }  else {
            for (var i = 0; i < selected.length; i++) {
                dummArray.push(selected[i].value.toString());
                dummArray_label.push(selected[i].label.toString());
            }
            setAssignedUsersRequestArray(dummArray);
            //setoptionAssignedUsersSelected(selected);
        } 
    };

    const handleChangect = (event) => {
        if(event.target.checked){
            setIsBillablect(1);
        } else{
            setIsBillablect(0);
        }
      };

      const handleEditChangect = (event) => {
        if(event.target.checked){
            setisbillablecheck(1);
        } else{
            setisbillablecheck(0);
        }
      }

      const handleTaskDurationInputChange = (event) => {
        const durationvalue = event.target.value;

        // Remove non-numeric characters and restrict length to 4
        const cleanedDurationValue = durationvalue.replace(/\D/g, '').slice(0, 4);

        setTaskInputDurationValue(cleanedDurationValue);
        setFormattedTaskTime(formatTimeToHHMM(cleanedDurationValue));
    };

    const formatTimeToHHMM = (value) => {
        if (value === '') return '';

        if (value.length <= 2) {
          return value; // Return as is if only two digits or less
        }

        const firstPart = value.slice(0, 2);
        const secondPart = value.slice(2);

        return `${firstPart}:${secondPart}`;
    };

    const handleTimeInputBlurCustom1 = (event) => {
        let value = event.target.value;
        //alert(value);
        // Extract value using a regular expression for partial inputs
        const timeRegex = /^(\d{1,4})(?::(\d{1,2}))?$/;
        //const timeRegex = /^(\d{4}):(\d{2})$/;

        const match = timeRegex.exec(value);


        let hours = '00';
        let minutes = '00';

        if (match) {
          hours = match[1] || '00'; // Default to '00' if hours are missing
          minutes = match[2] || '00'; // Default to '00' if minutes are missing
        }

        // Corrected time value
        const correctedTime = `${hours.padStart(4, '0')}:${minutes.padStart(2, '0')}`;

        // Update the input field directly
        event.target.value = correctedTime;

        // Optionally update the state if using controlled input
        // setTime(correctedTime);
      };

      const [inputValue, setInputValue] = useState('');
      const [formattedTime, setFormattedTime] = useState('');

      const handleChangeTime = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        console.log(value);
        setInputValue(value);

        if (value.length > 6) {
          setFormattedTime('Invalid input');
          return;
        }

        // Determine hours and minutes based on length of input
        let hours = '0000';
        let minutes = '00';

        switch (value.length) {
          case 1:
            minutes = `0${value}`;
            break;
          case 2:
            minutes = value;
            break;
          case 3:
            hours = `000${value.slice(0, 1)}`;
            minutes = value.slice(1);
            break;
          case 4:
            hours = `00${value.slice(0, 2)}`;
            minutes = value.slice(2);
            break;
          case 5:
            hours = `0${value.slice(0, 3)}`;
            minutes = value.slice(3);
            break;
          case 6:
            hours = value.slice(0, 4);
            minutes = value.slice(4);
            break;
          default:
            setFormattedTime('Invalid input');
            return;
        }

        console.log(`${hours}:${minutes}`);
        event.target.value = `${hours}:${minutes}`;
        setFormattedTime(`${hours}:${minutes}`);
      };

      /*
      const handleTimeInputBlurCustom = (event) => {
        let value = event.target.value;

        // Regular expression for HHHH:MM format
        const timeRegex = /^(\d{1,4})(?::(\d{2}))?$/;

        const match = timeRegex.exec(value);
        console.log(match);
        if(match){
            let hours = '0000';
            let minutes = '00';
    
            if (match) {
                hours = match[1] || '0000'; // Default to '0000' if hours are missing
                minutes = match[2] || '00'; // Default to '00' if minutes are missing
            }
    
            if(minutes > 59){
                minutes = '00';
            }
    
            // Corrected time value
            const correctedTime = `${hours.padStart(4, '0')}:${minutes.padStart(2, '0')}`;
    
            // Update the input field directly
            event.target.value = correctedTime;
        } else {
            event.target.value = '0000:0'+value;
        }

        

        // Optionally update the state if using controlled input
        // setTime(correctedTime);
    };
      */

    const handleTimeInputBlurCustom9 = (event) => {
        let value = event.target.value;
    
        // Regular expression for HHHH:MM format
        const timeRegex = /^(\d{1,4})(?::(\d{2}))?$/;
    
        const match = timeRegex.exec(value);
        if (match) {
            let hours = match[1] || '0000'; // Default to '0000' if hours are missing
            let minutes = match[2] || '00'; // Default to '00' if minutes are missing
    
            // If minutes exceed 59, reset to '00'
            if (parseInt(minutes, 10) > 59) {
                minutes = '00';
            }
    
            // Corrected time value with padding
            const correctedTime = `${hours.padStart(4, '0')}:${minutes.padStart(2, '0')}`;
    
            // Update the input field directly
            event.target.value = correctedTime;
        } else {
            // Fallback in case of invalid input, reset to '0000:00'
            event.target.value = '0000:00';
        }
    };


    const handleTimeInputBlurCustom = (event) => {
        let value = event.target.value;
    
        // Regular expression for HHHH:MM format
        const timeRegex = /^(\d{1,4})(?::(\d{2}))?$/;
    
        const match = timeRegex.exec(value);
        if (match) {
            let hours = match[1] || '0'; // Default to '0' if hours are missing
            let minutes = match[2] || '00'; // Default to '00' if minutes are missing
    
            // If minutes exceed 59, reset to '00'
            if (parseInt(minutes, 10) > 59) {
                minutes = '00';
            }
    
            // Remove leading zeros from hours but keep at least one digit
            hours = parseInt(hours, 10).toString();
    
            // Corrected time value
            const correctedTime = `${hours}:${minutes.padStart(2, '0')}`;
    
            // Update the input field directly
            event.target.value = correctedTime;
        } else {
            // Fallback in case of invalid input, reset to '0:00'
            event.target.value = '0:00';
        }
    };
    

    const [textvalue, setTextValue] = useState('');
    const maxLength = 500; // Set your character limit here

    const handleChangeTextarea = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= maxLength) {
            setTextValue(newValue);
        }
    };

    const [edittextvalue, setEditTextValue] = useState('');

    const handleChangeEditTextarea = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= maxLength) {
            setEditTextValue(newValue);
        }
    };

    const shouldSelectAll = optionAssignedUsersSelected.length === multiProjectAssignedUsers.length - 1;

  return(
  <>
      <div ref={modalRef} class="modal fade" id="exampleModalct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
        <form id="entryform" onSubmit={handleSubmit(submitValue)}>
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{getTitleHead()}</h5>
                    <button ref={closeButtonRef}  type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                {loadSpinner === 1 &&
                   <div class="loadwrapperpopup">
                   <div class="clock"><div class="circle"></div></div>
               </div>
              }

                <div class="modal-body" id="taskaddbody">

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Taks*</label>
                        <input type="text" class={`form-control ${tasknamevalidation === 1 ? 'errorinp' : ''}`} id="taskName" name="taskName" placeholder="Task Name"/>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Project*</label>
                        <select class={`form-select ${taskprojvalidation === 1 ? 'errorinp' : ''}`} id="project_name" aria-label="Default select example" value={projectSelected} onChange={handleProjectChange}>
                            <option value="" selected>Select Project</option>
                            {showProjectList.map((projectData, projectIndex) => (
                                <option value={projectData.projectId}>{projectData.projectName}</option>
                            ))}
                        </select>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Users*</label>
                         <ReactSelect
                                class="form-select"
                                options={multiProjectAssignedUsers}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                                onChange={handleChangeForAssignedUser}
                                allowSelectAll={true}
                                value={optionAssignedUsersSelected}

                                defaultValue={['1']}
                            /> 
{/*
<ReactSelect
    className="form-select"
    options={multiProjectAssignedUsers}
    isMulti
    closeMenuOnSelect={false}
    hideSelectedOptions={false}
    components={{ Option }}
    onChange={handleChangeForAssignedUser}
    value={optionAssignedUsersSelected} // Manage display value
    defaultValue={['1']}
/> */}
                    </div>
                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Sprint</label>
                        <input type="text" class="form-control" id="task_sprint" placeholder="Provide Sprint Details"/>
                    </div>
                    <div class="form-group mb-2">
                        <label for="recipient-name1" class="col-form-label">Task Description</label>
                        <div class="form-floating">
                            <textarea class="form-control pt-1" placeholder={`Write task description in max ${maxLength} characters`} value={textvalue} onChange={handleChangeTextarea} id="taskdescriptionct" ></textarea>
                        </div>
                        <small className="textAreaMaxLength">(Max 500 Characters)</small>
                    </div>

                    <div class="py-2">
                        <div class="d-grid justify-content-end">
                            <div class="d-flex align-items-center">
                                <span>Estimated Hours :</span>
                                {/* <input style={{maxWidth:"85px"}} type="text" id="taskdurationct" name="taskdurationct" className={`no-icon form-control ms-2 `} placeholder="0000:00" maxLength="8" onBlur={handleTimeInputBlurCustom} /> */}

                                <input
      style={{ maxWidth: '85px' }}
      type="text"
      id="taskdurationct"
      name="taskdurationct"
      className={`no-icon form-control ms-2`}
      placeholder="0000:00"
      maxLength="8"
      value={timeValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />




{/* ${newtaskdurationvalidation == 1 ? 'errorinp' : ''} */}
                            </div>
                            <div class="d-flex align-items-center gap-2 py-2 form-switch">
                                <label class="form-check-label" for="flexSwitchCheckDefault">Non-Billable</label>
                                {/* <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleChangect}/> */}
                                <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="editflexSwitchCheckDefault" onChange={handleEditChangect} checked={isbillablecheck}/>
                                <label class="form-check-label" for="flexSwitchCheckDefault">Billable</label>
                            </div>
                        </div>
                    </div>
                    {showValidation == 1 ?
                    <div className="validationglobal p-2" id="validationMessage">
                        <div className="validateitem">
                            {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                            <p>Please fill out all the required fields!</p>
                        </div>
                    </div>
                    : '' }
                </div>

                <div class="modal-body" id="taskeditbody">

                <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Taks*</label>
                        <input type="text" class={`form-control ${edittasknamevalidation === 1 ? 'errorinp' : ''}`} id="edittaskName" name="edittaskName" placeholder="Task Name" defaultValue={showTaskInfo.name}/>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Project*</label>
                        <select class={`form-select ${edittaskprojvalidation === 1 ? 'errorinp' : ''}`} id="editproject_name" aria-label="Default select example" onChange={handleProjectChange} value={projectSelected} disabled>
                            {showProjectList.map((projectData, projectIndex) => (
                                <option value={projectData.projectId}>{projectData.projectName}</option>
                            ))}
                        </select>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Users*</label>
                        <ReactSelect
                                class="form-select"
                                options={multiProjectAssignedUsers}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                                onChange={handleChangeForAssignedUser}
                               
                                value={optionAssignedUsersSelected}
                                defaultValue={['1']}
                            />
                    </div>
                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Sprint</label>
                        <input type="text" class="form-control" id="edittask_sprint" placeholder="Provide Sprint Details" defaultValue={showTaskInfo.sprint}/>
                    </div>
                    <div class="form-group mb-2">
                        <label for="recipient-name1" class="col-form-label"> Task Description </label>
                        <div class="form-floating">
                            <textarea class="form-control pt-1" placeholder={`Write task description in max ${maxLength} characters`} value={edittextvalue} onChange={handleChangeEditTextarea} id="edittaskdescriptionct" defaultValue={showTaskInfo.description}></textarea>
                        </div>
                        <small className="textAreaMaxLength">(Max 500 Characters)</small>
                    </div>

                    <div class="py-2">
                        <div class="d-grid justify-content-end">
                            <div class="d-flex align-items-center">
                                <span>Estimated Hours :</span>
                                {/* <input class={`form-control ms-2`} type="text" id="edittaskdurationct" placeholder="00:00" value={formattedTaskTime} onChange={handleTaskDurationInputChange}/>

                                <input type="time" id="edittaskdurationct" name="edittaskdurationct" className={`no-icon form-control ms-2`}  onBlur={handleTimeInputBlurCustom}/>
*/}
                                 {/* <input type="text" id="edittaskdurationct" name="edittaskdurationct" className={`no-icon form-control ms-2 `} maxLength="8" onBlur={handleTimeInputBlurCustom}/>  */}

                                 <input
      style={{ maxWidth: '85px' }}
      type="text"
      id="edittaskdurationct"
      name="edittaskdurationct"
      className={`no-icon form-control ms-2`}
      placeholder="0000:00"
      maxLength="8"
      value={editTimeValue}
      onChange={handleChangeEdit}
      onKeyDown={handleKeyDownEdit}
      onBlur={handleBlurEdit}
      onFocus={handleFocusEdit}
    /> 
                                {/* ${edittaskdurationvalidation == 1 ? 'errorinp' : ''} */}
                            </div>
                            <div class="d-flex align-items-center gap-2 py-2 form-switch">
                                <label class="form-check-label" for="flexSwitchCheckDefault">Non-Billable</label>
                                <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="editflexSwitchCheckDefault" checked={isBillablect} onChange={handleChangect}/>
                                <label class="form-check-label" for="flexSwitchCheckDefault">Billable</label>
                            </div>
                        </div>
                    </div>
                    {showValidation == 1 ?
                    <div className="validationglobal p-2" id="editvalidationMessage">
                        <div className="validateitem">
                            {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                            <p>Please fill out all the required fields!</p>
                        </div>
                    </div>
                    : '' }
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">{(!isEmpty(editTask)) ? 'Update'  : 'Create Task'  }</button>
                    <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>

                </div>

               
                <div class="px-3">
                <div id="successMessage" class="alert alert-success" role="alert">Task Created successfully!</div>
                <div id="updatesuccessMessage" class="alert alert-success" role="alert">Task Updated successfully!</div>
                <div id="failuresuccessMessage" class="alert alert-danger" role="alert"></div>
                </div>
            </div>
        </form>
        </div>
              
    </div>

  </>
)};

AddTask.propTypes = {};

AddTask.defaultProps = {};

export default AddTask;
