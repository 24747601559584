import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useExpanded } from 'react-table';
import services from "../../../services/services";
import Pagination from "../../../utility/Pagination/Pagination";// Adjust import based on your folder structure
import $ from 'jquery';

function ConfirmationPopup({ message, onConfirm, onCancel }) {
    return (
        <div className="popup">
            <div className="popup-content">
                <p>{message}</p>
                <button onClick={onConfirm} class="btn btn-primary me-2">Yes</button>
                <button onClick={onCancel} class="btn btn-outline-dark">No</button>
            </div>
        </div>
    );
}


const TeamTable = ({ projectid, teamstatus }) => {
    const [projectTeamMembers, setProjectTeamMembers] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [displayToggleStatus, setDisplayToggleStatus] = useState(0);
    const [showDetailLoader, setShowDetailLoader] = useState(0);
    const [pageSize] = useState(10); // Set the page size as required
    const [sortTeamConfig, setSortTeamConfig] = useState({ key: 'first_name', direction: 'ASC' });

    // Fetch project team data on component mount or when projectid/teamstatus changes
    useEffect(() => {
        getProjectTeam(projectid, teamstatus);
    }, [projectid, teamstatus, pageNum, sortTeamConfig]);

    const getProjectTeam = (projectid, teamstatus) => {
        const sortBy = sortTeamConfig.key;
        const sortOrder = sortTeamConfig.direction;
        
        services.getProjectTeamDetailsFullwithProjectIdAdmin(projectid, teamstatus, sortBy, sortOrder, pageNum, pageSize).then((res) => {
            if (res.data.type === 'success') {
                setProjectTeamMembers(res.data.data);
                //alert(res.data.pagination.totalNumberOfElements);
                setTotalRecords(res.data.pagination.totalNumberOfElements);
            }
        });
    };

    const handleCancel = () => {
        setShowPopup(false);
    }

    const handleUpdateTeam = () => {
        setShowDetailLoader(1);
        services.getUserById(userIdforUpdate).then((res) => {
            if (res.data.type === 'success') {
                var userResponse = res.data.data;

                const requestObject =
                {
                    "company_id": userResponse.company_id,
                    "first_name": userResponse.first_name,
                    "last_name": userResponse.last_name,
                    "email": userResponse.email,
                    "role_id": userResponse.role_id,
                    "designation_id": userResponse.designation_id,
                    "employee_id": userResponse.employee_id,
                    "status": userStatustoUpdate
                }
                var accessTokenIn = sessionStorage.getItem("accToken");
                // const requestOptions = {
                // method: 'PUT',
                // headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                // },
                // body: JSON.stringify(requestObject)
                // };

                $(".load-wrap").show();
                services.updateUserInformation(requestObject, userIdforUpdate).then((res) => {
                    console.log(res); //return false;
                    if (res.type == 'success') {

                        if (userStatustoUpdate === 1) {
                            setDisplayToggleStatus(0);
                            setTimeout(function () 
                            { 
                                //getProjectTeam(projectid, userStatustoUpdate); 
                                //setShowDetailLoader(0);
                                window.location.reload();
                             }, 1000);
                        }

                        if (userStatustoUpdate === 0) {
                            setDisplayToggleStatus(1);
                            setTimeout(function () 
                            { 
                                //getProjectTeam(projectid, userStatustoUpdate); 
                                //setShowDetailLoader(0);
                                window.location.reload();
                             }, 1000);
                        }

                    }
                });
            }
        });
        setShowPopup(false);

    }

    const [userIdforUpdate, setUserIdforUpdate] = useState(null);
    const [userStatustoUpdate, setUserStatustoUpdate] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const changeUserStatus = (userid, newStatus) => {
        setUserIdforUpdate(userid);
        setUserStatustoUpdate(newStatus);
        setShowPopup(true);
    }

    const handleTeamSort = (sortKey) => {
        setPageNum(1);
        setSortTeamConfig((prevSortConfig) => {
            let direction = 'ASC';
            if (prevSortConfig.key === sortKey && prevSortConfig.direction === 'ASC') {
                direction = 'DESC';
            }
            return { key: sortKey, direction };
        });
    };

    // Define columns for react-table
    const columns = useMemo(() => [
        {
           // Header: "Name",
            Header: (
                <span onClick={() => handleTeamSort('first_name')}>
                    Name {sortTeamConfig.key === 'first_name' ? (sortTeamConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                </span>
            ),
            disableSortBy: true,
            accessor: "user.first_name",
            Cell: ({ row }) => (
                <div>
                    {row.original.user.first_name + ' ' + row.original.user.last_name}
                    {row.original.user.role_id === 2 && (
                        <img src="images/manager-icon.svg" alt="Manager Icon" width={22} style={{ marginRight: "4px" }} />
                    )}
                </div>
            ),
            width: 235,
        },
        {
            Header: "Tasks",
            disableSortBy: true,
            accessor: "totalTasks",
            Cell: ({ value }) => (parseInt(value) > 0 ? value : ""),
            width: 103,
            className: "text-center",
        },
        {
            Header: "In Progress",
            disableSortBy: true,
            accessor: "inProgressTasks",
            Cell: ({ value }) => (parseInt(value) > 0 ? value : ""),
            width: 103,
            className: "text-center",
        },
        {
            Header: "Completed",
            disableSortBy: true,
            accessor: "completedTasks",
            Cell: ({ value }) => (parseInt(value) > 0 ? value : ""),
            width: 73,
            className: "text-center",
        },
        {
            Header: "Spent Hrs",
            disableSortBy: true,
            accessor: "totalHours",
            Cell: ({ value }) => (parseInt(value) > 0 ? value : ""),
            width: 103,
            className: "text-center",
        },
        {
            Header: "Billable Hrs",
            disableSortBy: true,
            accessor: "billableHours",
            Cell: ({ value }) => (parseInt(value) > 0 ? value : ""),
            width: 85,
            className: "text-center",
        },
        {
            Header: "Non-Billable Hrs",
            disableSortBy: true,
            accessor: "nonBillableHours",
            Cell: ({ value }) => (parseInt(value) > 0 ? value : ""),
            width: 123,
            className: "text-center",
        },
        {
            Header: "Actions",
            disableSortBy: true,
            Cell: ({ row }) => (
                <div className="dropdown table-action-dd">
                    <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown">
                        <img src="images/dots.svg" alt="Actions" />
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            {row.original.user.status === 1 ? (
                                <a className="dropdown-item" onClick={() => changeUserStatus(row.original.user.id, 0)}>
                                    <img src="images/redcheck.svg" width={19} alt="" /> Deactivate
                                </a>
                            ) : (
                                <a className="dropdown-item" onClick={() => changeUserStatus(row.original.user.id, 1)}>
                                    <img src="images/greencheck.svg" width={19} alt="" /> Activate
                                </a>
                            )}
                        </li>
                    </ul>
                </div>
            ),
            width: 75,
            className: "text-center",
        },
    ], [sortTeamConfig]);

    // Define the table instance
    const tableInstance = useTable(
        {
            columns,
            data: projectTeamMembers,
        },
        useSortBy,
        useExpanded
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    return (
        <div>
            <table {...getTableProps()} className="table table-condensed">
                <thead className="bg-lightblue">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ width: column.width }}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} className={cell.column.className} style={{ width: cell.column.width }}>
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

          
            {/* Pagination Component */}
            {/* <Pagination
                currentPage={pageNum}
                totalRecords={totalRecords}
                pageSize={pageSize}
                onPageChange={setPageNum}
            /> */}

            <div class="paginationContent mt-3">
                <Pagination
                    total={totalRecords}
                    itemsPerPage={pageSize}
                    currentPage={pageNum}
                    onPageChange={setPageNum} />

            </div>

            {showPopup && (
    <ConfirmationPopup
        message="Are you sure you want to Update this Team Member?"
        onConfirm={handleUpdateTeam}
        onCancel={handleCancel}
    />
)}


            {showDetailLoader === 1 ?
                <div class="loadwrapper">
                    <div class="clock"><div class="circle"></div></div>
                </div>
                : ''}

        </div>


    );
};

export default TeamTable;
