import React, { useEffect, useState, useMemo, useRef } from "react";
import services from "../../services/services";
import $ from 'jquery';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Pagination from "../../utility/Pagination/Pagination";
import { getCurrentDateInEST, convertDateStringToEST, convertDateStringToGMT, timezone } from "../../timezone/dateUtils";
import { useTable, useSortBy } from 'react-table';
import ReactExport from "react-data-export";
import {
    format,
    startOfDay,
    startOfWeek,
    startOfMonth,
    endOfWeek,
    endOfMonth,
    addDays,
    addWeeks,
    addMonths,
    subDays,
    subWeeks,
    subMonths,
} from "date-fns";

const getCurrentUTCDate = () => {
    const now = new Date();
    return new Date(new Date().toISOString().split('T')[0]);
};

const Reports = () => {
    const printRefClients = useRef();
    const printRefProjects = useRef();
    const printRefTasks = useRef();
    const printRefHead = useRef();
    const isInitialMountTask = useRef(true);
    const isInitialMountUser = useRef(true);
    const isInitialMountProject = useRef(true);
    const isInitialMountRangeType = useRef(true);
    const printDetailedReportRefHeaderPagePrime = useRef();
    const [date, setDate] = useState(getCurrentDateInEST());
    const pageNum = 1;
    const ITEMS_PER_PAGE = 15;
    const [companyPrintLogo, setCompanyPrintLogo] = useState("images/timesheet-logo.png");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState(1);
    const [displayDate, setDisplayDate] = useState('');
    const [userFullName, setUserFullName] = useState(sessionStorage.getItem("userFullName"));
    const [userCompanyName, setUserCompanyName] = useState(sessionStorage.getItem("userCompanyName"));

    const [totalPages, setTotalPages] = useState(1); // Set this from the API response

    // States for pagination
    const [clientCurrentPage, setClientCurrentPage] = useState(1);
    const [clientTotalPages, setClientTotalPages] = useState(1);

    const [projectCurrentPage, setProjectCurrentPage] = useState(1);
    const [projectTotalPages, setProjectTotalPages] = useState(1);

    const [taskCurrentPage, setTaskCurrentPage] = useState(1);
    const [taskTotalPages, setTaskTotalPages] = useState(1);

    const pageSize = 15;

    ////////////////////////////////////////////////////////////////////////////////
    const [rangeType, setRangeType] = useState("Month"); // Default to Day
    //const [currentDate, setCurrentDate] = useState(new Date(new Date().toISOString().split('T')[0]));
    const [currentDate, setCurrentDate] = useState(getCurrentDateInEST());
    //const [weekStartDatePost, setWeekStartDatePost] = useState(format(currentDate, "yyyy-MM-dd"));

    const [weekStartDatePost, setWeekStartDatePost] = useState(format(startOfMonth(getCurrentDateInEST()), "yyyy-MM-dd"));


    //const [weekEndDatePost, setWeekEndDatePost] = useState(format(currentDate, "yyyy-MM-dd"));
    // const [weekEndDatePost, setWeekEndDatePost] = useState(
    //     format(addMonths(currentDate, 1), "yyyy-MM-dd")
    // );

    const [weekEndDatePost, setWeekEndDatePost] = useState(format(endOfMonth(getCurrentDateInEST()), "yyyy-MM-dd"));
    const [clientWiseStatsUser, setClientWiseStatsUser] = useState({ clients: [] });
    const [projectWiseStatsUser, setProjectWiseStatsUser] = useState({ projects: [] });
    const [taskWiseStatsUser, setTaskWiseStatsUser] = useState({ tasks: [] });


    const [clientWiseStatsUserFull, setClientWiseStatsUserFull] = useState({ clients: [] });
    const [projectWiseStatsUserFull, setProjectWiseStatsUserFull] = useState({ projects: [] });
    const [taskWiseStatsUserFull, setTaskWiseStatsUserFull] = useState({ tasks: [] });

    const [allClientsTotalHours, setAllClientsTotalHours] = useState('');
    const [allClientsBillableHours, setAllClientsBillableHours] = useState('');
    const [allClientsNonBillableHours, setAllClientsNonBillableHours] = useState('');

    const [allProjectsTotalHours, setAllProjectsTotalHours] = useState('');
    const [allProjectsBillableHours, setAllProjectsBillableHours] = useState('');
    const [allProjectsNonBillableHours, setAllProjectsNonBillableHours] = useState('');

    const [allTasksTotalHours, setAllTasksTotalHours] = useState('');
    const [allTasksBillableHours, setAllTasksBillableHours] = useState('');
    const [allTasksNonBillableHours, setAllTasksNonBillableHours] = useState('');

    const [isCheckedClient, setIsCheckedClient] = useState(false);
    const [isCheckedProject, setIsCheckedProject] = useState(false);
    const [isCheckedTask, setIsCheckedTask] = useState(false);
    const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));

    const [projects, setProjects] = useState([]);
    const [clients, setClients] = useState([]);

    const [selectedProject, setSelectedProject] = useState('');
    const [selectedProjectName, setSelectedProjectName] = useState('');

    const [selectedClient, setSelectedClient] = useState('');
    const [selectedClientName, setSelectedClientName] = useState('');

    const [selectedTaskBillable, setSelectedTaskBillable] = useState('');
    const [selectedProjectBillable, setSelectedProjectBillable] = useState('');
    const [selectedClientBillable, setSelectedClientBillable] = useState('');

    const [showLoader, setShowLoader] = useState(0);

    const [sortProjectConfig, setSortProjectConfig] = useState({ key: 'projectWiseBillableHours', direction: 'ASC' });
    const [sortClientConfig, setSortClientConfig] = useState({ key: 'clientWiseBillableHours', direction: 'ASC' });
    const [sortTaskConfig, setSortTaskConfig] = useState({ key: '', direction: 'DESC' });

    // const handleProjectChange = (event) => {
    //     setSelectedProject(event.target.value);
    // };

    const handleProjectChange = (event) => {
        setTaskCurrentPage(1);
        const selectedProjectId = event.target.value;
        const selectedProjectObj = projects.find(project => project.id == selectedProjectId);
        setSelectedClientName('');
        if (selectedProjectObj) {
          setSelectedProject(selectedProjectObj.id);  // Store selected project's ID
          setSelectedProjectName(selectedProjectObj.name);  // Store selected project's name
        } else {
          setSelectedProject('');  // Reset if no project selected
          setSelectedProjectName('');  // Reset project name
        }
      };

    const handleTaskBillableChange = (event) => {
        setSelectedTaskBillable(event.target.value);
    }

    // const handleClientChange = (event) => {
    //     setSelectedClient(event.target.value);
    // };


    const handleClientChange = (event) => {
        const selectedClientId = event.target.value;
        setSelectedClient(selectedClientId);
        setSelectedProjectName('');

        const selectedClientObj = clients.find(client => client.id == selectedClientId);
        if (selectedClientObj) {
          setSelectedClientName(selectedClientObj.client_name);  // Store selected client's name
        } else {
          setSelectedClientName('');  // Reset client name
        }
      };

    const handleProjectBillableChange = (event) => {
        setSelectedProjectBillable(event.target.value);
    }

    const handleClientBillableChange = (event) => {
        setSelectedClientBillable(event.target.value);
    }

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;
        switch (id) {
            case 'clientcheckbox':
                setIsCheckedClient(checked);
                break;
            case 'projectcheckbox':
                setIsCheckedProject(checked);
                break;
            case 'taskcheckbox':
                setIsCheckedTask(checked);
                break;
            default:
                break;
        }
    };
    useEffect(() => {

        if (isInitialMountRangeType.current) {
            isInitialMountRangeType.current = false;
        } else {
            adjustDateToRangeType(rangeType);
        }



    }, [rangeType]);

    useEffect(() => {
        const loggedUserCompanyId = sessionStorage.getItem('userCompanyId');
        setCompanyPrintLogo(services.fetchServerAPIEndPoint()+"uploads/company-logo/company_logo_file_"+loggedUserCompanyId+".png");
    }, []);

    useEffect(() => {
        fetchProjectsAndClients();
    }, []);

    const adjustDateToRangeType = (type) => {
        let newStartDate, newEndDate;
        // setCurrentDate(new Date(new Date().toISOString().split('T')[0]));
        // let currentDate_t = new Date(new Date().toISOString().split('T')[0]);

        setCurrentDate(getCurrentDateInEST());
        let currentDate_t = getCurrentDateInEST();

        switch (type) {
            case "Day":
                newStartDate = newEndDate = currentDate_t; // Keep the current date as is
                break;
            case "Week":
                newStartDate = startOfWeek(currentDate_t, { weekStartsOn: 1 }); // Start of the current week
                newEndDate = endOfWeek(currentDate_t, { weekStartsOn: 1 }); // End of the current week
                break;
            case "Month":
                newStartDate = startOfMonth(currentDate_t); // Start of the current month
                newEndDate = endOfMonth(currentDate_t); // End of the current month
                break;
            default:
                newStartDate = newEndDate = currentDate_t;
        }
        //alert(currentDate)
        console.log("currentDate--->" + currentDate_t);
        console.log("newStartDate--->" + newStartDate);
        setCurrentDate(newStartDate);
        setWeekStartDatePost(format(newStartDate, "yyyy-MM-dd"));
        setWeekEndDatePost(format(newEndDate, "yyyy-MM-dd"));
    };

    const adjustDateRange = (direction) => {
        let newStartDate, newEndDate;

        switch (rangeType) {
            case "Day":
                newStartDate = newEndDate = direction === "prev" ? subDays(currentDate, 1) : addDays(currentDate, 1);
                break;
            case "Week":
                const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 }); // Monday as the first day
                newStartDate = direction === "prev" ? subWeeks(startOfCurrentWeek, 1) : addWeeks(startOfCurrentWeek, 1);
                newEndDate = endOfWeek(newStartDate, { weekStartsOn: 1 });
                break;
            case "Month":
                const startOfCurrentMonth = startOfMonth(currentDate);
                newStartDate = direction === "prev" ? subMonths(startOfCurrentMonth, 1) : addMonths(startOfCurrentMonth, 1);
                newEndDate = endOfMonth(newStartDate);
                break;
            default:
                newStartDate = newEndDate = currentDate;
        }
        setCurrentDate(newStartDate);
        setWeekStartDatePost(format(newStartDate, "yyyy-MM-dd"));
        setWeekEndDatePost(format(newEndDate, "yyyy-MM-dd"));

    };

    const resetToCurrent = () => {
        //setCurrentDate(getCurrentUTCDate()); // Reset to current UTC date
        //const now = getCurrentUTCDate();

        const now = new Date(new Date().toISOString().split('T')[0]);
        //setCurrentDate(now);
        setCurrentDate(new Date(new Date().toISOString().split('T')[0]));
        setWeekStartDatePost(format(now, "yyyy-MM-dd"));
        setWeekEndDatePost(format(now, "yyyy-MM-dd"));
    };

    const formatDateRange = () => {
        const start = format(currentDate, "EEEE, dd MMM yyyy"); // Start date formatted
        let end;
        switch (rangeType) {
            case "Day":
                end = start; // Same day
                break;
            case "Week":
                end = format(endOfWeek(currentDate, { weekStartsOn: 1 }), "dd MMM, yyyy"); // End of week
                break;
            case "Month":
                end = format(endOfMonth(currentDate), "dd MMM, yyyy"); // End of month
                break;
            default:
                end = start;
        }
        if (rangeType == "Day") {
            return `${start}`;
        } else {
            // return `${start} - ${end}`;
            return `${format(currentDate, "dd MMM")} - ${end}`;
        }

    };
    useEffect(() => {
        //fetchReportsUser();
        if (isInitialMountUser.current) {
            isInitialMountUser.current = false;
        } else {
            fetchReportsUser();
        }

    }, [weekStartDatePost, weekEndDatePost, isCheckedClient, clientCurrentPage, selectedClientBillable, sortClientConfig]);

    useEffect(() => {
        //fetchReportsProject();

        if (isInitialMountProject.current) {
            isInitialMountProject.current = false;
            const timer = setTimeout(() => {
                fetchReportsProject();
              }, 1500);
        } else {
            fetchReportsProject();
        }
    }, [weekStartDatePost, weekEndDatePost, isCheckedProject, selectedClient, projectCurrentPage, selectedProjectBillable, sortProjectConfig]);

    useEffect(() => {
        //fetchReportsTask();
        if (isInitialMountTask.current) {
            isInitialMountTask.current = false;
        } else {
            fetchReportsTask();
        }
    }, [weekStartDatePost, weekEndDatePost, isCheckedTask, selectedProject, taskCurrentPage, selectedTaskBillable, sortTaskConfig]);

    const fetchProjectsAndClients = async () => {
        const requestJson = {
            userId: useridval,
        };
        const result1_fetchProjectByUser = await services.fetchProjectByUser(requestJson);

        const projects = result1_fetchProjectByUser.map(project => ({
            id: project.id,
            name: project.name
        }));
        // Check if project.client is defined before accessing its properties
        const clients = result1_fetchProjectByUser
            .filter(project => project.client) // Filter out projects without a client
            .map(project => ({
                id: project.client.id,
                client_name: project.client.client_name
            }));

        // Remove duplicate clients if necessary
        const uniqueClients = Array.from(new Map(clients.map(client => [client.id, client])).values());

        setProjects(projects);
        setClients(uniqueClients);

    }

    const handleClientSort = (columnKey) => {
        let direction = 'ASC';
        // Toggle sorting direction if the column is already being sorted
        if (sortClientConfig.key === columnKey && sortClientConfig.direction === 'ASC') {
            direction = 'DESC';
        }
        setClientCurrentPage(1);
        setSortClientConfig({ key: columnKey, direction });
        //getProjectsByUserId('', useridval, projectClientSelected, statusForProject, direction, columnKey);
    }

    const fetchReportsUser = async () => {
        setShowLoader(1);
        let clientProjectStatus = "";
        if (isCheckedClient) {
            clientProjectStatus = "1";
        }
        const requestJsonClient = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: clientProjectStatus,
            "pageNumber": clientCurrentPage,
            "pageSize": pageSize,
            "sortOrder":sortClientConfig.direction,
            "sortBy":sortClientConfig.key
        };

        const result1_fetchClientWiseStatsUser = await services.fetchClientWiseStatsUser(requestJsonClient);
        setClientWiseStatsUser(result1_fetchClientWiseStatsUser);


        const requestJsonClientFull = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: clientProjectStatus,
            isBillable:selectedClientBillable
            //"pageNumber": clientCurrentPage,
            //"pageSize": pageSize
        };

        const result1_fetchClientWiseStatsUserFull = await services.fetchClientWiseStatsUser(requestJsonClientFull);
        setClientWiseStatsUserFull(result1_fetchClientWiseStatsUserFull);


        setClientTotalPages(result1_fetchClientWiseStatsUser.totalNumberOfPages);

        setAllClientsTotalHours(result1_fetchClientWiseStatsUser.allClientsTotalHours);
        setAllClientsBillableHours(result1_fetchClientWiseStatsUser.allClientsBillableHours);
        setAllClientsNonBillableHours(result1_fetchClientWiseStatsUser.allClientsNonBillableHours);
        setShowLoader(0);
    };

    const handleProjectSort = (columnKey) => {
        let direction = 'ASC';
        // Toggle sorting direction if the column is already being sorted
        if (sortProjectConfig.key === columnKey && sortProjectConfig.direction === 'ASC') {
            direction = 'DESC';
        }
        setProjectCurrentPage(1);
        setSortProjectConfig({ key: columnKey, direction });
        //getProjectsByUserId('', useridval, projectClientSelected, statusForProject, direction, columnKey);
    }

    const fetchReportsProject = async () => {
        setShowLoader(1);
        let projectProjectStatus = "";
        if (isCheckedProject) {
            projectProjectStatus = "1";
        }
        //alert(sortProjectConfig.key+' - '+sortProjectConfig.direction);
        const requestJsonProject = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: projectProjectStatus,
            clientId: selectedClient,
            "pageNumber": projectCurrentPage,
            "pageSize": pageSize,
            "sortOrder":sortProjectConfig.direction,
            "sortBy":sortProjectConfig.key
        };

        const result1_fetchProjectWiseStatsUser = await services.fetchProjectWiseStatsUser(requestJsonProject);
        setProjectWiseStatsUser(result1_fetchProjectWiseStatsUser);


        const requestJsonProjectFull = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: projectProjectStatus,
            clientId: selectedClient,
            //isBillable:selectedProjectBillable
            //"pageNumber": projectCurrentPage,
            //"pageSize": pageSize
        };

        const result1_fetchProjectWiseStatsUserFull = await services.fetchProjectWiseStatsUser(requestJsonProjectFull);
        setProjectWiseStatsUserFull(result1_fetchProjectWiseStatsUserFull);

        setProjectTotalPages(result1_fetchProjectWiseStatsUser.totalNumberOfPages);

        setAllProjectsTotalHours(result1_fetchProjectWiseStatsUser.allProjectsTotalHours);
        setAllProjectsBillableHours(result1_fetchProjectWiseStatsUser.allProjectsBillableHours);
        setAllProjectsNonBillableHours(result1_fetchProjectWiseStatsUser.allProjectsNonBillableHours);
        setShowLoader(0);
    };

    const handleTaskSort = (columnKey) => {
        let direction = 'ASC';
        // Toggle sorting direction if the column is already being sorted
        if (sortTaskConfig.key === columnKey && sortTaskConfig.direction === 'ASC') {
            direction = 'DESC';
        }
        setTaskCurrentPage(1);
        setSortTaskConfig({ key: columnKey, direction });
    }

    const fetchReportsTask = async () => {
        setShowLoader(1);
        let taskProjectStatus = "";
        if (isCheckedTask) {
            taskProjectStatus = "1";
        }

        const requestJsonTask = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: taskProjectStatus,
            projectId: selectedProject,
            "pageNumber": taskCurrentPage,
            "pageSize": pageSize,
            "sortOrder":sortTaskConfig.direction,
            "sortBy": sortTaskConfig.key
            //"isBillable":selectedTaskBillable
        };
        const result1_fetchTaskWiseStatsUser = await services.fetchTaskWiseStatsUser(requestJsonTask);
        setTaskWiseStatsUser(result1_fetchTaskWiseStatsUser);


        const requestJsonTaskFull = {
            weekStartDate: weekStartDatePost,
            weekEndDate: weekEndDatePost,
            userId: useridval,
            projectStatus: taskProjectStatus,
            projectId: selectedProject,
            isBillable:selectedTaskBillable,
            "sortOrder":sortTaskConfig.direction,
            "sortBy": sortTaskConfig.key
            // "pageNumber": taskCurrentPage,
            // "pageSize": pageSize
        };
        const result1_fetchTaskWiseStatsUserFull = await services.fetchTaskWiseStatsUser(requestJsonTaskFull);
        setTaskWiseStatsUserFull(result1_fetchTaskWiseStatsUserFull);


        setTaskTotalPages(result1_fetchTaskWiseStatsUser.totalPages);

        setAllTasksTotalHours(result1_fetchTaskWiseStatsUser.allTasksTotalHours);
        setAllTasksBillableHours(result1_fetchTaskWiseStatsUser.allTasksBillableHours);
        setAllTasksNonBillableHours(result1_fetchTaskWiseStatsUser.allTasksNonBillableHours);
        setShowLoader(0);
    };

    const renderTable = (columns, data = [], totalHours, totalBillableHours, totalNonBillableHours, colspan, selectedBillable) => {
        const tableInstance = useTable({ columns, data }, useSortBy);

        return (
            <table {...tableInstance.getTableProps()} className="table mb-0 lastchild-color">
                {/* Table header */}
                <thead className="bg-lightblue">
                    {tableInstance.headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    {/*<span
                                        style={{
                                            //position: 'absolute',
                                            paddingLeft: '10px',
                                            right: '10px',
                                            top: '50%',
                                            //transform: 'translateY(-50%)',
                                            display: 'inline-block',
                                            fontSize: '0.8em',
                                            color: '#888',
                                        }}
                                    >
                                        {!column.disableSortBy ? (column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '↕️') : null}
                                    </span> */}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {/* Table body */}
                <tbody {...tableInstance.getTableBodyProps()}>
                {tableInstance.rows.length > 0 ? (
                    <>
                    {tableInstance.rows.map(row => {
                        tableInstance.prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                            {(parseInt(selectedBillable) === 1 || parseInt(selectedBillable) === 0) ?
                                (parseInt(selectedBillable) === 1 ?
                                    <tr>
                                        <td colSpan={colspan}><b>Total</b></td>
                                        <td><b>{totalBillableHours}</b></td>
                                        <td><b>{totalHours}</b></td>
                                    </tr>
                                    :
                                    <tr>
                                        <td colSpan={colspan}><b>Total</b></td>
                                        <td><b>{totalNonBillableHours}</b></td>
                                        <td><b>{totalHours}</b></td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td colSpan={colspan}><b>Total</b></td>
                                    <td><b>{totalBillableHours}</b></td>
                                    <td><b>{totalNonBillableHours}</b></td>
                                    <td><b>{totalHours}</b></td>
                                </tr>
                            }
                    </>
                    ) : (
                        <tr>
                            <td colSpan={parseInt(colspan + 3)}>
                                <div className="d-grid text-center py-3">
                                    <img src="images/emptybox.svg" className="m-auto" alt="" width={128} />
                                    <span> No etries available.</span>
                                </div>
                            </td>
                        </tr>
                )}

                </tbody>
            </table>
        );
    };


    const clientColumns = useMemo(
        () => {
            let columns = [
                {
                    Header: (
                        <span onClick={() => handleClientSort('clientName')}>
                            Client <span class="no-print">{sortClientConfig.key === 'clientName' ? (sortClientConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                        </span>
                    ),
                    accessor: "clientName",
                    //sortType: 'basic',
                },
            ];

            if (parseInt(selectedClientBillable) === 1 || parseInt(selectedClientBillable) === 0) {
                if (parseInt(selectedClientBillable) === 1) {
                    // Show only Billable Hrs when isBillable is 1
                    columns.push({
                        Header: (
                            <span onClick={() => handleClientSort('clientWiseBillableHours')}>
                                Billable Hrs <span class="no-print">{sortClientConfig.key === 'clientWiseBillableHours' ? (sortClientConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                            </span>
                        ),
                        accessor: "clientWiseBillableHours",
                        //sortType: 'basic'
                    });
                }
                if (parseInt(selectedClientBillable) === 0) {
                    // Show only Non-Billable Hrs when isBillable is 0
                    columns.push({
                        Header: (
                            <span onClick={() => handleClientSort('clientWiseNonBillableHours')}>
                                Non-Billable Hrs <span class="no-print">{sortClientConfig.key === 'clientWiseNonBillableHours' ? (sortClientConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                            </span>
                        ),
                        accessor: "clientWiseNonBillableHours",
                        //sortType: 'basic'
                    });
                }
            } else {
                columns.push({
                    Header: (
                        <span onClick={() => handleClientSort('clientWiseBillableHours')}>
                            Billable Hrs <span class="no-print">{sortClientConfig.key === 'clientWiseBillableHours' ? (sortClientConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                        </span>
                    ),
                    accessor: "clientWiseBillableHours",
                    //sortType: 'basic'
                });
                columns.push({
                    Header: (
                        <span onClick={() => handleClientSort('clientWiseNonBillableHours')}>
                            Non-Billable Hrs <span class="no-print">{sortClientConfig.key === 'clientWiseNonBillableHours' ? (sortClientConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                        </span>
                    ),
                    accessor: "clientWiseNonBillableHours",
                    //sortType: 'basic'
                });
            }

            columns.push({
                Header: (
                    <span onClick={() => handleClientSort('clientWiseTotalHours')}>
                        Total Hrs <span class="no-print">{sortClientConfig.key === 'clientWiseTotalHours' ? (sortClientConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                    </span>
                ),
                accessor: "clientWiseTotalHours",
                //sortType: 'basic'
            });
            return columns;
        },
        [selectedClientBillable, sortClientConfig] // Add isBillable as a dependency
    );



    const projectColumns = useMemo(
        () => {
            let columns = [
            {
                Header: (
                    <span onClick={() => handleProjectSort('projectName')}>
                        Project <span class="no-print">{sortProjectConfig.key === 'projectName' ? (sortProjectConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                    </span>
                ),
                accessor: "projectName",
                //sortType: 'basic',
            },
            {
                Header: (
                    <span onClick={() => handleProjectSort('clientName')}>
                        Client <span class="no-print">{sortProjectConfig.key === 'clientName' ? (sortProjectConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                    </span>
                ),
                accessor: "clientName",
                //sortType: 'basic',
            },
            ];

            if (parseInt(selectedProjectBillable) === 1 || parseInt(selectedProjectBillable) === 0) {
                if (parseInt(selectedProjectBillable) === 1) {
                    // Show only Billable Hrs when isBillable is 1
                    columns.push({
                        Header: (
                            <span onClick={() => handleProjectSort('projectWiseBillableHours')}>
                                Billable Hrs <span class="no-print">{sortProjectConfig.key === 'projectWiseBillableHours' ? (sortProjectConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                            </span>
                        ),
                        accessor: "projectWiseBillableHours",
                        //sortType: 'basic'
                    });
                }
                if (parseInt(selectedProjectBillable) === 0) {
                    // Show only Non-Billable Hrs when isBillable is 0
                    columns.push({
                        Header: (
                            <span onClick={() => handleProjectSort('projectWiseNonBillableHours')}>
                                Non-Billable Hrs <span class="no-print">{sortProjectConfig.key === 'projectWiseNonBillableHours' ? (sortProjectConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                            </span>
                        ),
                        accessor: "projectWiseNonBillableHours",
                        //sortType: 'basic'
                    });
                }
            } else {
                columns.push({
                    Header: (
                        <span onClick={() => handleProjectSort('projectWiseBillableHours')}>
                            Billable Hrs <span class="no-print">{sortProjectConfig.key === 'projectWiseBillableHours' ? (sortProjectConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                        </span>
                    ),
                    accessor: "projectWiseBillableHours",
                    //sortType: 'basic'
                });
                columns.push({
                    Header: (
                        <span onClick={() => handleProjectSort('projectWiseNonBillableHours')}>
                            Non-Billable Hrs <span class="no-print">{sortProjectConfig.key === 'projectWiseNonBillableHours' ?  (sortProjectConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                        </span>
                    ),
                    accessor: "projectWiseNonBillableHours",
                    //sortType: 'basic'
                });
            }

            columns.push({
                Header: (
                    <span onClick={() => handleProjectSort('projectWiseTotalHours')}>
                        Total Hrs <span class="no-print">{sortProjectConfig.key === 'projectWiseTotalHours' ? (sortProjectConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                    </span>
                ),
                accessor: "projectWiseTotalHours",
                //sortType: 'basic'
            });
            return columns;
        },
        [selectedProjectBillable, sortProjectConfig] // Add isBillable as a dependency
    );
/*
    const taskColumns = useMemo(
        () => [
            { Header: "Task", accessor: "taskName", sortType: 'basic', },
            { Header: "Project", accessor: "projectName", sortType: 'basic', },
            { Header: "Client", accessor: "clientName", sortType: 'basic', },
            { Header: "Billable Hrs", accessor: "billableHours", sortType: 'basic', },
            { Header: "Non-Billable Hrs", accessor: "nonBillableHours", sortType: 'basic', },
            { Header: "Total Hrs", accessor: "totalHours", sortType: 'basic', },
        ],
        []
    ); */

    const taskColumns = useMemo(
        () => {
            let columns = [
                {
                    Header: (
                        <span onClick={() => handleTaskSort('taskName')}>
                            Task <span class="no-print">{sortTaskConfig.key === 'taskName' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                        </span>
                    ),
                    accessor: "taskName",
                    //sortType: 'basic'
                },
                {
                    Header: (
                        <span onClick={() => handleTaskSort('projectName')}>
                            Project <span class="no-print">{sortTaskConfig.key === 'projectName' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                        </span>
                    ),
                    accessor: "projectName",
                    //sortType: 'basic'
                },
                {
                    Header: (
                        <span onClick={() => handleTaskSort('clientName')}>
                            Client <span class="no-print">{sortTaskConfig.key === 'clientName' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                        </span>
                    ),
                    accessor: "clientName",
                    //sortType: 'basic'
                },
            ];

            if (parseInt(selectedTaskBillable) === 1 || parseInt(selectedTaskBillable) === 0) {
                if (parseInt(selectedTaskBillable) === 1) {
                    // Show only Billable Hrs when isBillable is 1
                    columns.push({
                        Header: (
                            <span onClick={() => handleTaskSort('billableHours')}>
                                Billable Hrs <span class="no-print">{sortTaskConfig.key === 'billableHours' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                            </span>
                        ),
                        accessor: "billableHours",
                        //sortType: 'basic'
                    });
                }
                if (parseInt(selectedTaskBillable) === 0) {
                    // Show only Non-Billable Hrs when isBillable is 0
                    columns.push({
                        Header: (
                            <span onClick={() => handleTaskSort('nonBillableHours')}>
                                Non-Billable Hrs <span class="no-print">{sortTaskConfig.key === 'nonBillableHours' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                            </span>
                        ),
                        accessor: "nonBillableHours",
                        //sortType: 'basic'
                    });
                }
            } else {
                columns.push({
                    Header: (
                        <span onClick={() => handleTaskSort('billableHours')}>
                            Billable Hrs <span class="no-print">{sortTaskConfig.key === 'billableHours' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                        </span>
                    ),
                    accessor: "billableHours",
                    //sortType: 'basic'
                });
                columns.push({
                    Header: (
                        <span onClick={() => handleTaskSort('nonBillableHours')}>
                            Non-Billable Hrs <span class="no-print">{sortTaskConfig.key === 'nonBillableHours' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                        </span>
                    ),
                    accessor: "nonBillableHours",
                    //sortType: 'basic'
                });
            }
            columns.push({
                Header: "Total Hrs",
                Header: (
                    <span onClick={() => handleTaskSort('totalHours')}>
                        Total Hrs <span class="no-print">{sortTaskConfig.key === 'totalHours' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}</span>
                    </span>
                ),
                accessor: "totalHours",
                //sortType: 'basic'
            });

            return columns;
        },
        [selectedTaskBillable, sortTaskConfig] // Add isBillable as a dependency
    );

    /////////////////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////


    const Pagination = () => {
        const pageNumbers = generatePageNumbers();

        return (
            <div className="pagination-controls">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    « Prev
                </button>

                {pageNumbers.map(page => (
                    <button
                        key={page}
                        onClick={() => setCurrentPage(page)}
                        className={page === currentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next »
                </button>
            </div>
        );
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };




    //#############################
    // Pagination Handlers for Clients
    const handleClientPrevPage = () => {
        if (clientCurrentPage > 1) {
            setClientCurrentPage(clientCurrentPage - 1);
        }
    };

    const handleClientNextPage = () => {
        if (clientCurrentPage < clientTotalPages) {
            setClientCurrentPage(clientCurrentPage + 1);
        }
    };

    // Pagination Handlers for Projects
    const handleProjectPrevPage = () => {
        if (projectCurrentPage > 1) {
            setProjectCurrentPage(projectCurrentPage - 1);
        }
    };

    const handleProjectNextPage = () => {
        if (projectCurrentPage < projectTotalPages) {
            setProjectCurrentPage(projectCurrentPage + 1);
        }
    };

    // Pagination Handlers for Tasks
    const handleTaskPrevPage = () => {
        if (taskCurrentPage > 1) {
            setTaskCurrentPage(taskCurrentPage - 1);
        }
    };

    const handleTaskNextPage = () => {
        if (taskCurrentPage < taskTotalPages) {
            setTaskCurrentPage(taskCurrentPage + 1);
        }
    };
    const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
    }

    // Example of rendering paginations independently
    const renderClientPagination = () => {
        const pageNumbers = generatePageNumbers(clientCurrentPage, clientTotalPages);
        if(!isEmpty(pageNumbers)){
            return (
                <div className="pagination-controls justify-content-end">
                    <button onClick={handleClientPrevPage} disabled={clientCurrentPage === 1}>
                        « Prev
                    </button>

                    {pageNumbers.map(page => (
                        <button
                            key={page}
                            onClick={() => setClientCurrentPage(page)}
                            className={page === clientCurrentPage ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}

                    <button onClick={handleClientNextPage} disabled={clientCurrentPage === clientTotalPages}>
                        Next »
                    </button>
                </div>
            );
        }

    };

    const renderProjectPagination = () => {
        const pageNumbers = generatePageNumbers(projectCurrentPage, projectTotalPages);
if(!isEmpty(pageNumbers)){
    return (
        <div className="pagination-controls justify-content-end">
            <button onClick={handleProjectPrevPage} disabled={projectCurrentPage === 1}>
                « Prev
            </button>

            {pageNumbers.map(page => (
                <button
                    key={page}
                    onClick={() => setProjectCurrentPage(page)}
                    className={page === projectCurrentPage ? 'active' : ''}
                >
                    {page}
                </button>
            ))}

            <button onClick={handleProjectNextPage} disabled={projectCurrentPage === projectTotalPages}>
                Next »
            </button>
        </div>
    );
}

    };

    const renderTaskPagination = () => {
        const pageNumbers = generatePageNumbers(taskCurrentPage, taskTotalPages);
if(!isEmpty(pageNumbers)){
    return (
        <div className="pagination-controls justify-content-end">
            <button onClick={handleTaskPrevPage} disabled={taskCurrentPage === 1}>
                « Prev
            </button>

            {pageNumbers.map(page => (
                <button
                    key={page}
                    onClick={() => setTaskCurrentPage(page)}
                    className={page === taskCurrentPage ? 'active' : ''}
                >
                    {page}
                </button>
            ))}

            <button onClick={handleTaskNextPage} disabled={taskCurrentPage === taskTotalPages}>
                Next »
            </button>
        </div>
    );
}

    };

    // Helper function to generate page numbers
    const generatePageNumbers = (currentPage, totalPages) => {
        const maxPageButtons = 5;
        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrent = Math.floor(maxPageButtons / 2);
            const maxPagesAfterCurrent = Math.ceil(maxPageButtons / 2) - 1;

            if (currentPage <= maxPagesBeforeCurrent) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + maxPagesAfterCurrent >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrent;
                endPage = currentPage + maxPagesAfterCurrent;
            }
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };




    //############################
    //////////////////////////////////////////


    ////########### print @@@@@@@@@@@@@@@@@@@@@@@@@@
    const renderClientTable = (columns, data = [], totalHours, totalBillableHours, totalNonBillableHours, colspan) => {
        const tableInstance = useTable({ columns, data }, useSortBy);
        return (
            <table {...tableInstance.getTableProps()} className="table mb-0 lastchild-color">
                <thead className="bg-lightblue">
                    {tableInstance.headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                // <th {...column.getHeaderProps(column.getSortByToggleProps())}></th>
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                   {/* <span style={{
                                        //position: 'absolute',
                                        paddingLeft: '10px',
                                        right: '10px',
                                        top: '50%',
                                        //transform: 'translateY(-50%)',
                                        display: 'inline-block',
                                        fontSize: '0.8em',
                                        color: '#888',
                                    }}>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '↕️'}</span> */}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...tableInstance.getTableBodyProps()}>
                    {tableInstance.rows.map((row) => {
                        tableInstance.prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>

                        );
                    })}
                    <tr>
                        <td colSpan={colspan}><b>Total </b></td>
                        <td><b>{totalBillableHours}</b></td>
                        <td><b>{totalNonBillableHours}</b></td>
                        <td><b>{totalHours}</b></td>
                    </tr>
                </tbody>
            </table>
        );
    };


    const renderClientTableForPrint22 = (columns, data = [], totalHours, totalBillableHours, totalNonBillableHours, colspan) => {
        const tableInstance = useTable({ columns, data }, useSortBy);
        return (
            <table {...tableInstance.getTableProps()} className="table mb-0 lastchild-color">
                <thead className="bg-lightblue">
                    {tableInstance.headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                   
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...tableInstance.getTableBodyProps()}>
                    {tableInstance.rows.map((row) => {
                        tableInstance.prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>

                        );
                    })}
                    <tr>
                        <td colSpan={colspan}><b>Total </b></td>
                        <td><b>{totalBillableHours}</b></td>
                        <td><b>{totalNonBillableHours}</b></td>
                        <td><b>{totalHours}</b></td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const renderClientTableForPrint = (
        columns,
        data = [],
        totalHours,
        totalBillableHours,
        totalNonBillableHours,
        colspan,
        selectedBillable
      ) => {
        const tableInstance = useTable({ columns, data }, useSortBy);
      
        // Helper function to split data based on page size
        const splitDataForPages = (data, firstPageSize, subsequentPageSize) => {
          const firstPage = data.slice(0, firstPageSize);
          const subsequentPages = [];
      
          for (let i = firstPageSize; i < data.length; i += subsequentPageSize) {
            subsequentPages.push(data.slice(i, i + subsequentPageSize));
          }
      
          return [firstPage, ...subsequentPages];
        };
      
        // Split the data: 8 records for the first page, 11 for subsequent pages
        const pages = splitDataForPages(data, 8, 11);
       //const pages = splitDataForPages(data, 3, 2);
      
        return (
          <>
            {pages.map((pageData, pageIndex) => (

              

              <table
                key={pageIndex}
                {...tableInstance.getTableProps()}
                className = "table mb-0 lastchild-color content"
                // style={pageIndex > 0 ? { marginTop: '255px' } : {}}
                style={{ marginTop: pageIndex >= 8 ? '250px' : '0', position: 'relative', top: pageIndex >= 8 ? '110px' : '0' }}
              >
                
                  {/* Render header on every page */}
                  {tableInstance.headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  ))}
              
                <tbody {...tableInstance.getTableBodyProps()}>
                  {/* Render rows for current page */}
                  {pageData.map((row, rowIndex) => {
                    const preparedRow = tableInstance.rows[rowIndex];
                    tableInstance.prepareRow(preparedRow);
                    return (
                      <tr key={rowIndex} {...preparedRow.getRowProps()}>
                        {preparedRow.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                      </tr>
                    );
                  })}
                  {/* Render totals only on the last page */}
                        {pageIndex === pages.length - 1 && (
                            <tr>
                               
                                {(parseInt(selectedBillable) === 1) ? (
                                    <>
                                        <td colSpan={colspan}>
                                            <b>Total</b>
                                        </td>
                                        <td><b>{totalBillableHours}</b></td>
                                        <td><b> {totalHours}</b></td>
                                    </>
                                ) : (parseInt(selectedBillable) === 0) ? (
                                    <>
                                            <td colSpan={colspan}>
                                                <b>Total</b>
                                            </td>
                                        <td><b>{totalNonBillableHours}</b></td>
                                        <td><b> {totalHours}</b></td>
                                    </>
                                ) : (
                                    <>
                                        <td><b>{totalBillableHours}</b></td>
                                        <td><b>{totalNonBillableHours}</b></td>
                                        <td><b> {totalHours}</b></td>
                                    </>
                                )}
                            </tr>
                        )}


                </tbody>
              </table>
            
            ))}
          </>
        );
      };
      

    const renderClientTableForPrint_upd = (columns, data = [], totalHours, totalBillableHours, totalNonBillableHours, colspan) => {
        const tableInstance = useTable({ columns, data }, useSortBy);
        const rowsPerPage = 8; // Number of rows for the first table
        const subsequentRowsPerPage = 11; // Number of rows for subsequent tables
    
        return (
            <div className="tbl-container bdr mt-0">
                {/* Check if there is any data */}
                {tableInstance.rows.length > 0 ? (
                    tableInstance.rows.map((row, index) => {
                        // Prepare the row for rendering
                        tableInstance.prepareRow(row);
    
                        // Calculate the starting index for each group of rows
                        const groupStartIndex = index < rowsPerPage ? 0 : rowsPerPage + Math.floor((index - rowsPerPage) / subsequentRowsPerPage) * subsequentRowsPerPage;
    
                        // Logic for table rendering, first table has rowsPerPage, subsequent ones have subsequentRowsPerPage
                        if (index === 0 || (index >= rowsPerPage && (index - rowsPerPage) % subsequentRowsPerPage === 0)) {
                            return (
                                <React.Fragment key={`group-${index}`}>
                                    {/* Render table, apply margin to subsequent tables */}
                                    <table
                                        {...tableInstance.getTableProps()}
                                        className="table mb-0 content"
                                        style={{
                                            marginTop: index >= rowsPerPage ? '250px' : '0',
                                            position: 'relative',
                                            top: index >= rowsPerPage ? '110px' : '0'
                                        }}
                                    >
                                        <thead className="bg-lightblue">
                                            {tableInstance.headerGroups.map((headerGroup) => (
                                                <th {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <tr {...column.getHeaderProps()}>
                                                            {column.render('Header')}
                                                        </tr>
                                                    ))}
                                                </th>
                                            ))}
                                        </thead>
                                        <tbody {...tableInstance.getTableBodyProps()}>
                                            {/* Slice the rows for the current table group */}
                                            {tableInstance.rows.slice(groupStartIndex, groupStartIndex + (index === 0 ? rowsPerPage : subsequentRowsPerPage)).map((subRow) => {
                                                tableInstance.prepareRow(subRow);
                                                return (
                                                    <tr {...subRow.getRowProps()}>
                                                        {subRow.cells.map((cell) => (
                                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        ))}
                                                    </tr>
                                                );
                                            })}

                                            <tr>
                                                <td colSpan={colspan}><b>Total </b></td>
                                                <td><b>{totalBillableHours}</b></td>
                                                <td><b>{totalNonBillableHours}</b></td>
                                                <td><b>{totalHours}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            );
                        }
    
                        return null; // Return null if the condition isn't met for creating a new table
                    })
                ) : (
                    // If no rows are available, show an empty message
                    <table className="table mb-0">
                        <tbody>
                            <tr>
                                <td colSpan={colspan}>
                                    <div className="d-grid text-center py-3">
                                        <img src="images/emptybox.svg" className="m-auto" alt="" width={128} />
                                        <span>No entries available</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
    
                {/* Total row (rendered after all tables) */}
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td colSpan={colspan}><b>Total </b></td>
                            <td><b>{totalBillableHours}</b></td>
                            <td><b>{totalNonBillableHours}</b></td>
                            <td><b>{totalHours}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };
    

    const handlePrintClients = () => {
        const contentToPrint = printRefClients.current;
        const printRefHeadtoPrint = printRefHead.current;

        const pageHeader = printDetailedReportRefHeaderPagePrime.current;

        // Get the current date and time
        const now = new Date();
        const formattedDate = now.toLocaleDateString(); // Get the date in 'MM/DD/YYYY' format
        const formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Get time in 'HH:MM' format

        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.write(`
          <html>
            <head>
              <title>Employee Timesheet Reports</title>
              <link href="/css/bootstrap.min.css" rel="stylesheet">
                <link href="/css/style-guide.css" rel="stylesheet">
                <link href="/assets/custome.css" rel="stylesheet">
           <style>
                                .item small {font-size:11px}.mainbluetxt {font-size:1.1em}
                /* Hide elements with the class 'no-print' during print */
                .no-print {
                    display: none !important;
                }

                /* Set font sizes for table content */
                th, td {
                    font-size: 10px;
                    padding-top:3px; padding-bottom:3px;
                }

                /* Add margins for the printed content */
                body {
                    margin: 25px;
                }

                /* Media query to apply specific styles for printing */
                @media print {

                    body {
                        margin: 0;
                        padding: 0;
                    }

                    /* Create a margin to account for the footer */

                        /* Fixed header at the top of every printed page */
                    header {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        text-align: center;
                        font-size: 12px;
                        border-bottom: 1px solid #000;

                        padding: 5px 0;
                        background: #fff; /* Ensure background is white */
                    }
                       .content {
                            page-break-inside: auto;
                            page-break-before: auto;
                            page-break-after: always;
                            margin-top:22mm;
                            padding: 0px 20px 60px 20px;
                     }

                    footer {
                        position: fixed;
                        bottom: 100px;
                        left: 0;
                        right: 0;
                        width:100%;
                        display:flex;
                        font-size: 10px;
                        }

                    .page-break {
                            page-break-before: always;
                    }
                }

            </style>
            </head>
            <body>

             <header>
                             ${pageHeader.innerHTML}
                          </header>




               <div class="page-break"></div>
             <div class="content">
            ${printRefHeadtoPrint.innerHTML}
            ${contentToPrint.innerHTML}</div>
             <div class="page-break"></div>


               <footer>

                              <table style="width:100%; border-top:1px solid; position:absolute; bottom:-100px"
                                    <tr style="padding-top:15px;">
                                        <td> <div class="d-flex gap-1">
                      <span>Printed on :</span> <span>${formattedDate}</span> <span>@</span> <span>${formattedTime}</span>
                    </div></td>
                                        <td style="text-align:right"> <img style="padding-top:10px;" src="images/timesheet-logo.png" class="print-logo" height="30" alt="" /></td>
                                        </tr>
                                </table>
                          </footer>
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        // printWindow.close();
    };

    const handlePrintProjects = () => {
        const printRefHeadtoPrint = printRefHead.current;
        const contentToPrint = printRefProjects.current;

        const pageHeader = printDetailedReportRefHeaderPagePrime.current;

        // Get the current date and time
        const now = new Date();
        const formattedDate = now.toLocaleDateString(); // Get the date in 'MM/DD/YYYY' format
        const formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Get time in 'HH:MM' format
        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.write(`
          <html>
            <head>
              <title>Employee Timesheet Reports</title>
              <link href="/css/bootstrap.min.css" rel="stylesheet">
                <link href="/css/style-guide.css" rel="stylesheet">
                <link href="/assets/custome.css" rel="stylesheet">
             <style>
                /* Hide elements with the class 'no-print' during print */
                 .item small {font-size:11px}.mainbluetxt {font-size:1.1em}
                .billnonbill { margin-bottom:15px}
                .no-print {
                    display: none !important;
                }

                /* Set font sizes for table content */
                th, td {
                    font-size: 11px;
                    padding-top:3px; padding-bottom:3px;
                }

                /* Add margins for the printed content */
                body {
                    margin: 25px;
                }

                /* Media query to apply specific styles for printing */
                @media print {

                    body {
                        margin: 0;
                        padding: 0;
                    }

                    /* Create a margin to account for the footer */

                        /* Fixed header at the top of every printed page */
                    header {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        text-align: center;
                        font-size: 12px;
                        border-bottom: 1px solid #000;

                        padding: 5px 0;
                        background: #fff; /* Ensure background is white */
                    }
                       .content {
                            page-break-inside: auto;
                            page-break-before: auto;
                            page-break-after: always;
                            margin-top:18mm;

                     }

                    footer {
                        position: fixed;
                        bottom: 100px;
                        left: 0;
                        right: 0;
                        width:100%;
                        display:flex;
                        font-size: 10px;
                        }

                    .page-break {
                            page-break-before: always;
                    }
                }

            </style>
            </head>
            <body>

             <header>
                             ${pageHeader.innerHTML}
                          </header>

             <div class="content">
            ${printRefHeadtoPrint.innerHTML}
            ${contentToPrint.innerHTML}</div>
             <div class="page-break"></div>




               <footer>

                              <table style="width:100%; border-top:1px solid; position:absolute; bottom:-100px"
                                    <tr style="padding-top:15px;">
                                        <td> <div class="d-flex gap-1">
                      <span>Printed on :</span> <span>${formattedDate}</span> <span>@</span> <span>${formattedTime}</span>
                    </div></td>
                                        <td style="text-align:right"> <img style="padding-top:10px;" src="images/timesheet-logo.png" class="print-logo" height="40" alt="" /></td>
                                        </tr>
                                </table>
                          </footer>
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        //printWindow.close();
    };

    const handlePrintTasks = () => {
        const printRefHeadtoPrint = printRefHead.current;
        const contentToPrint = printRefTasks.current;
        const pageHeader = printDetailedReportRefHeaderPagePrime.current;

        // Get the current date and time
        const now = new Date();
        const formattedDate = now.toLocaleDateString(); // Get the date in 'MM/DD/YYYY' format
        const formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Get time in 'HH:MM' format

        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.write(`
          <html>
            <head>
              <title>Employee Timesheet Reports</title>
                <link href="/css/bootstrap.min.css" rel="stylesheet">
                <link href="/css/style-guide.css" rel="stylesheet">
                <link href="/assets/custome.css" rel="stylesheet">
             <style>
                /* Hide elements with the class 'no-print' during print */
                .billnonbill { margin-bottom:15px}
                .no-print {
                    display: none !important;
                }

                /* Set font sizes for table content */
                th, td {
                    font-size: 11px;
                    padding-top:3px; padding-bottom:3px;
                }

                /* Add margins for the printed content */
                body {
                    margin: 25px;
                }

                /* Media query to apply specific styles for printing */
                @media print {

                    body {
                        margin: 0;
                        padding: 0;
                    }

                    /* Create a margin to account for the footer */

                        /* Fixed header at the top of every printed page */
                    header {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        text-align: center;
                        font-size: 12px;
                        border-bottom: 1px solid #000;

                        padding: 5px 0;
                        background: #fff; /* Ensure background is white */
                    }
                       .content {
                           page-break-inside: auto;
                            page-break-before: auto;
                            page-break-after: always;
                            margin-top:21mm;
                            padding-bottom:35mm
                     }

                    footer {
                        position: fixed;
                        bottom: 100px;
                        left: 0;
                        right: 0;
                        width:100%;
                        display:flex;
                        font-size: 10px;
                        }

                    .page-break {
                            page-break-before: always;
                    }
                }

            </style>
            </head>
            <body>

            <header>
                             ${pageHeader.innerHTML}
                          </header>

                          <div class="page-break"></div>



            <div class="content">
            ${printRefHeadtoPrint.innerHTML}
            ${contentToPrint.innerHTML}</div>
             <div class="page-break"></div>

               <footer>

                              <table style="width:100%; border-top:1px solid; position:absolute; bottom:-100px"
                                    <tr style="padding-top:15px;">
                                        <td> <div class="d-flex gap-1">
                      <span>Printed on :</span> <span>${formattedDate}</span> <span>@</span> <span>${formattedTime}</span>
                    </div></td>
                                        <td style="text-align:right"> <img style="padding-top:10px;" src="images/timesheet-logo.png" class="print-logo" height="30" alt="" /></td>
                                        </tr>
                                </table>
                          </footer>


            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        //printWindow.close();
    };


const [tabClick, setTabClick] = useState('project');
const tabCheck = (tab) => {
    setTabClick(tab);
   if(tab == 'task'){
    fetchReportsTask();
   }else if(tab == 'client'){
    fetchReportsUser();
   }else  if(tab == 'project'){
    fetchReportsProject();
   }
}
    /////////////////////////////////////
    return (
        <>
            <main>


                <section>
                    <div class="container">
                        <h2 class="mainHeadline">
                            Reports
                        </h2>

                        <div class="tab-content-bg-primary position-relative" >

                            <div class="d-flex justify-content-between ">
                                <div className="d-grod">
                                <div className="print-logo-area d-flex align-items-center gap-2 mb-3">
                                {/* <img src={companyPrintLogo} className="print-logo" height={36} alt="" /> */}

                                </div>
                                <div class="d-flex">
                                    <div class="prev-after-wrap no-print">
                                        <a onClick={() => adjustDateRange("prev")}> <span> <img src="images/left.png" alt="" /></span></a>
                                        <a onClick={() => adjustDateRange("next")}><span> <img src="images/right.png" alt="" /></span></a>
                                    </div>
                                    <div class="daytitlewrap ">
                                        <span class="d-flex gap-2">
                                            <img class="icon no-print" src="images/cal-icon.png" alt="" />
                                            <div class="d-flex align-items-center">
                                                {/* <h2> <span className="userhd">{userFullName}</span></h2> */}
                                                <h2>{formatDateRange()}</h2>
                                                <div className="d-flex">
                                                    {/*<span className="px-2">|</span>
                                                     <small className="companylabel">{userCompanyName}</small> */}
                                                </div>
                                                {/* <a href="#"><img src="images/returnArrow.png" style="margin-right: 4px;" alt="">Return to Today</a> */}

                                            </div>

                                        </span></div>
                                        </div></div>
                                <div class="dayweekswitch no-print">
                                    <div class="d-flex justify-content-end align-items-center">

                                        <select class="form-select" value={rangeType} onChange={(e) => setRangeType(e.target.value)} style={{ "max-width": "200px" }}>
                                            <option value="Day">Day</option>
                                            <option value="Week">Weekly</option>
                                            <option value="Month">Monthly</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div class="my-3"></div>
                            <div class="d-flex justify-content-between">
                                 {/* <div className="d-grid">
                                    <div className="d-flex gap-1 align-items-center">
                                        <small>Client:</small>
                                        <b>ABC</b>
                                    </div>
                                      <div className="d-flex gap-1 align-items-center">
                                        <small>Project:</small>
                                        <b>ABC</b>
                                    </div>
                                </div>  */}
                                <div class="billnonbill d-flex gap-4 align-items-center me-5">
                                    <div class="item d-grid">
                                        <small> Total hours</small>
                                        <strong class="mainbluetxt ">{tabClick === 'client' ? allClientsTotalHours : tabClick === 'project' ? allProjectsTotalHours : allTasksTotalHours}</strong>
                                    </div>
                                    <span>|</span>
                                    <div class="item d-grid">
                                        <small class="d-flex gap-1 align-items-center"> <img src="images/billed.png" width="20" alt="" /> Billable</small>
                                        <strong class="mainbluetxt ">{tabClick === 'client' ? allClientsBillableHours : tabClick === 'project' ? allProjectsBillableHours : allTasksBillableHours}</strong>
                                    </div>
                                    <span>|</span>
                                    <div class="item d-grid">
                                        <small class="d-flex gap-1 align-items-center"> <img src="images/dollarred.svg" width="20" alt="" />
                                            Non-Billable</small>
                                        <strong class="mainbluetxt ">{tabClick === 'client' ? allClientsNonBillableHours : tabClick === 'project' ? allProjectsNonBillableHours : allTasksNonBillableHours}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="tab-content-bg-primary position-relative d-none" ref={printRefHead}>

                            <div class="d-flex justify-content-between ">
                                <div className="d-grod">
                                <div className="print-logo-area d-flex align-items-center gap-2 mb-3">
                                {/* <img src={companyPrintLogo} className="print-logo" height={36} alt="" /> */}

                                </div>
                                <div class="d-flex">
                                    <div class="prev-after-wrap no-print">
                                        <a onClick={() => adjustDateRange("prev")}> <span> <img src="images/left.png" alt="" /></span></a>
                                        <a onClick={() => adjustDateRange("next")}><span> <img src="images/right.png" alt="" /></span></a>
                                    </div>
                                    <div class="daytitlewrap ">
                                        <span class="d-flex gap-2">
                                            <img class="icon no-print" src="images/cal-icon.png" alt="" />
                                            <div class="d-flex align-items-center">
                                                {/* <h2> <span className="userhd">{userFullName}</span></h2> */}
                                                {/* <h2>{formatDateRange()}</h2> */}
                                                {/* <div className="d-flex"> */}
                                                    {/*<span className="px-2">|</span>
                                                     <small className="companylabel">{userCompanyName}</small> */}
                                                {/* </div> */}
                                                {/* <a href="#"><img src="images/returnArrow.png" style="margin-right: 4px;" alt="">Return to Today</a> */}

                                            </div>

                                        </span></div>
                                        </div></div>
                                <div class="dayweekswitch no-print">
                                    <div class="d-flex justify-content-end align-items-center">

                                        <select class="form-select" value={rangeType} onChange={(e) => setRangeType(e.target.value)} style={{ "max-width": "200px" }}>
                                            <option value="Day">Day</option>
                                            <option value="Week">Weekly</option>
                                            <option value="Month">Monthly</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div class="my-3"></div>
                            <div class="d-flex justify-content-between">
                                <div className="d-grid">
                                    {selectedClientName && (
                                        <div className="d-flex gap-1 align-items-center">
                                            <small>Client:</small>
                                            <b>{selectedClientName}</b>
                                        </div>
                                    )}

                                    {selectedProjectName && (
                                        <div className="d-flex gap-1 align-items-center">
                                            <small>Project:</small>
                                            <b>{selectedProjectName}</b>
                                        </div>
                                    )}


                                </div>
                                <div class="billnonbill d-flex gap-4 align-items-center me-5">
                                    <div class="item d-grid">
                                        <small> Total hours</small>
                                        <strong class="mainbluetxt ">{tabClick === 'client' ? allClientsTotalHours : tabClick === 'project' ? allProjectsTotalHours : allTasksTotalHours}</strong>
                                    </div>
                                    <span>|</span>
                                    <div class="item d-grid">
                                        <small class="d-flex gap-1 align-items-center"> <img src="images/billed.png" width="20" alt="" /> Billable</small>
                                        <strong class="mainbluetxt ">{tabClick === 'client' ? allClientsBillableHours : tabClick === 'project' ? allProjectsBillableHours : allTasksBillableHours}</strong>
                                    </div>
                                    <span>|</span>
                                    <div class="item d-grid">
                                        <small class="d-flex gap-1 align-items-center"> <img src="images/dollarred.svg" width="20" alt="" />
                                            Non-Billable</small>
                                        <strong class="mainbluetxt ">{tabClick === 'client' ? allClientsNonBillableHours : tabClick === 'project' ? allProjectsNonBillableHours : allTasksNonBillableHours}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-primary my-4">

                            <ul class="nav nav-tabs" role="tablist">

                                <li class="nav-item">
                                    <a class="nav-link bg-none" data-bs-toggle="tab" onClick={() => tabCheck('client')} href="#menu1">Clients</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link active bg-none" onClick={() => tabCheck('project')} data-bs-toggle="tab" href="#menu2">Projects </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link bg-none" onClick={() => tabCheck('task')} data-bs-toggle="tab" href="#menu3">Tasks </a>
                                </li>

                            </ul>

                            <div class="tab-content ">

                                <div id="menu1" class="container tab-pane fade  px-0">
                                    <div class="d-flex justify-content-between align-items-center py-2">
                                        <span class="d-flex align-items-center gap-2">

                                        </span>
                                        <div class="d-flex justify-content-end align-items-center" style={{ "width": "400px" }}>
                                            <span style={{ "min-width": "69px" }}>Filter by:</span>

                                            <select
                                                className="form-select me-2"
                                                id="clientBillableSelect"
                                                style={{ maxWidth: '200px' }}
                                                value={selectedClientBillable}
                                                onChange={handleClientBillableChange}
                                            >
                                                <option value="">All</option>
                                                <option value="1">Billable</option>
                                                <option value="0">Non-Billable</option>
                                            </select>

                                            <button class="btn btn-primary" onClick={handlePrintClients}><img src="images/print.svg" width={24} alt="" />Print</button>
                                        </div>
                                    </div>

                                    <div class="tbl-container bdr mt-0 mb-3">
                                        {renderTable(clientColumns, clientWiseStatsUser.clients || [], clientWiseStatsUser.totalClientsHoursOfPage, clientWiseStatsUser.totalClientsBillableHoursOfPage, clientWiseStatsUser.totalClientsNonBillableHoursOfPage, 1, selectedClientBillable)}
                                    </div>
                                    {renderClientPagination()}



                                </div>


                                {/* Hidden div for printing */}
                                <div style={{ display: 'none' }}>
                                    <div ref={printRefClients}>
                                        {renderClientTableForPrint(clientColumns, clientWiseStatsUserFull.clients || [], allClientsTotalHours, clientWiseStatsUserFull.allClientsBillableHours, clientWiseStatsUserFull.allClientsNonBillableHours, 1, selectedClientBillable)}
                                    </div>
                                </div>


                                <div id="menu2" class="container tab-pane fade show active px-0">
                                    <div class="d-flex justify-content-between align-items-center py-2">
                                        <span class="d-flex align-items-center  gap-2">
                                            {/*<input
                                                className="form-check-input mt-0"
                                                type="checkbox"
                                                id="projectcheckbox"
                                                checked={isCheckedProject}
                                                onChange={handleCheckboxChange}
                                            />
                                            <small><strong>Active Projects Only</strong></small> */}
                                        </span>

                                        <div class="d-flex justify-content-end align-items-center gap-2" style={{ "width": "630px" }}>
                                            <span style={{ "min-width": "69px" }}>Filter by:</span>


                                            <select
                                                className="form-select me-2"
                                                id="clientSelect"
                                                style={{ maxWidth: '200px' }}
                                                value={selectedClient}
                                                onChange={handleClientChange}
                                            >
                                                <option value="">All Clients</option>
                                                {clients.map(client => (
                                                    <option key={client.id} value={client.id}>
                                                        {client.client_name}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                className="form-select me-2"
                                                id="ProjectBillableSelect"
                                                style={{ maxWidth: '200px' }}
                                                value={selectedProjectBillable}
                                                onChange={handleProjectBillableChange}
                                            >
                                                <option value="">All</option>
                                                <option value="1">Billable</option>
                                                <option value="0">Non-Billable</option>
                                            </select>
                                            <button class="btn btn-primary" onClick={handlePrintProjects}><img src="images/print.svg" width={24} alt="" /> Print</button>


                                        </div>


                                    </div>

                                    <div class="tbl-container bdr mt-0 mb-3">
                                        {renderTable(projectColumns, projectWiseStatsUser.projects, projectWiseStatsUser.totalProjectsHoursOfPage, projectWiseStatsUser.totalProjectsBillableHoursOfPage, projectWiseStatsUser.totalProjectsNonBillableHoursOfPage, 2, selectedProjectBillable)}
                                    </div>

                                    {renderProjectPagination()}

                                </div>

                                {/* Hidden div for printing */}
                                <div style={{ display: 'none' }}>
                                    <div ref={printRefProjects}>
                                        {renderClientTableForPrint(projectColumns, projectWiseStatsUserFull.projects || [], projectWiseStatsUserFull.allProjectsTotalHours, projectWiseStatsUserFull.allProjectsBillableHours, projectWiseStatsUserFull.allProjectsNonBillableHours, 2, selectedProjectBillable)}
                                    </div>
                                </div>

                                <div id="menu3" class="container tab-pane fade px-0">
                                    <div class="d-flex justify-content-between align-items-center py-2">
                                        <span class="d-flex align-items-center gap-2">
                                            {/*s<input
                                                className="form-check-input mt-0"
                                                type="checkbox"
                                                id="taskcheckbox"
                                                checked={isCheckedTask}
                                                onChange={handleCheckboxChange}
                                            />
                                            <small><strong>Active Projects Only</strong></small> */}
                                        </span>

                                        <div class="d-flex justify-content-end align-items-center gap-2" style={{ "width": "630px" }}>
                                            <span style={{ "min-width": "69px" }}>Filter by:</span>




                                            <select
                                                className="form-select me-2"
                                                id="projectSelect"
                                                style={{ maxWidth: '200px' }}
                                                value={selectedProject}
                                                onChange={handleProjectChange}
                                            >
                                                <option value="">All Projects</option>
                                                {projects.map(project => (
                                                    <option key={project.id} value={project.id}>
                                                        {project.name}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                className="form-select me-2"
                                                id="taskBillableSelect"
                                                style={{ maxWidth: '200px' }}
                                                value={selectedTaskBillable}
                                                onChange={handleTaskBillableChange}
                                            >
                                                <option value="">All</option>
                                                <option value="1">Billable</option>
                                                <option value="0">Non-Billable</option>
                                            </select>

                                            <button class="btn btn-primary" onClick={handlePrintTasks}> <img src="images/print.svg" width={24} alt="" /> Print</button>
                                        </div>
                                    </div>

                                    <div class="tbl-container bdr mt-0 mb-3">

                                        {renderTable(taskColumns, taskWiseStatsUser.tasks, taskWiseStatsUser.totalTasksHoursOfPage, taskWiseStatsUser.totalTasksBillableHoursOfPage, taskWiseStatsUser.totalTasksNonBillableHoursOfPage, 3, selectedTaskBillable)}

                                    </div>

                                    <div class="">
                                        {renderTaskPagination()}

                                    </div>

                                    {/* Hidden div for printing */}
                                    <div style={{ display: 'none' }}>

                                        <div ref={printDetailedReportRefHeaderPagePrime}>
                                            <div className=" print-header d-flex justify-content-between  pb-2 mb-2">
                                                <div className="d-grid">
                                                    <div className="print-logo-area d-flex align-items-center gap-2 mb-0">
                                                        <img src={companyPrintLogo} className="print-logo" height={36} alt="" />
                                                    </div>
                                                    <b>
                                                        <span>{userCompanyName}</span>
                                                    </b>
                                                </div>
                                                <div className="text-end d-grid">
                                                    <h4>Time Report</h4>
                                                    <div className="d-flex justify-content-end align-items-center gap-2 "><label>Period:</label> <h5>{formatDateRange()}</h5> </div>
                                                    <div className="d-flex justify-content-end align-items-center gap-2 ">For: <h5>{userFullName}</h5></div>
                                                </div>
                                            </div>

                                        </div>
                                        
                                        
                                        <div ref={printRefTasks}>
                                            {renderClientTableForPrint(taskColumns, taskWiseStatsUserFull.tasks, allTasksTotalHours, taskWiseStatsUserFull.allTasksBillableHours, taskWiseStatsUserFull.allTasksNonBillableHours, 3, selectedTaskBillable)}
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                        {showLoader === 1 ?
                            <div class="loadwrapper">
                                <div class="clock"><div class="circle"></div></div>
                            </div>
                            : ''}
                    </div>
                </section>

            </main>
        </>
    )
};

Reports.propTypes = {};

Reports.defaultProps = {};

export default Reports;